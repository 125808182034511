import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { FiX } from "react-icons/fi";

import { Button } from "components/Button";
import { SearchInput } from "components/SearchInput";
import { ClientProps } from "dtos/ClientDTO";
import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";

import { api } from "services/api";
import { EditButton } from "components/EditButton";
import { FormEditClient } from "components/ModalPageClients/FormEditClient";
import { useEditClient } from "hooks/editClient";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";
import { useAuth } from "hooks/auth";

type ListClientProps = {
  handleToggleOpen: () => void;
  handleSwapCreateClient: () => void;
  module?: string;
};

type TOptions = {
  [key: string]: string;
};

export function ListClients({
  handleToggleOpen,
  handleSwapCreateClient,
  module,
}: ListClientProps) {
  const theme = useTheme();
  const { addToast } = useToast();
  const { handleSetClient, client } = useClient();
  const { handleSetEditClient } = useEditClient();
  const { user } = useAuth();

  const [searchInput, setSearchInput] = useState("");

  const [clients, setClients] = useState<ClientProps[]>([]);
  const [clientsResponse, setClientsResponse] = useState<ClientProps[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const routeValidate = (module: string | undefined) => {
    const defaultRoute = "/clients";
    const options: TOptions = {
      pmoc: defaultRoute + `?accountId=${user.id}`,
      budget: defaultRoute,
      serviceorder: defaultRoute,
      client: defaultRoute,
      default: defaultRoute,
    };
    return options[module ? module.toLowerCase() : "default"];
  };

  async function fetchClients() {
    try {
      const { data } = await api.get(routeValidate(module));

      const mappedClients = data.clients.map((client: ClientProps) => {
        const splittedName = client.name.split(" ");

        const firstLetter = splittedName[0].substring(0, 1);
        const lastLetter =
          splittedName.length >= 2
            ? splittedName[splittedName.length - 1].substring(0, 1)
            : "";

        return {
          ...client,
          initialsLetterName: `${firstLetter}${lastLetter}`,
        };
      });

      setClients(mappedClients);
      setClientsResponse(mappedClients);
    } catch (err) {
      // sendError(err);
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os contatos",
        type: "error",
      });
    }
  }

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast]);

  function handleSelectedClient(client: ClientProps) {
    handleSetClient(client);
    handleToggleOpen();
  }

  function handleSearchClient(text: string) {
    setSearchInput(text);

    const filteredClients = clientsResponse.filter((client) => {
      const clientsLowerCase = client.name.toLowerCase();

      const searchValue = text.toLowerCase();

      return clientsLowerCase.includes(searchValue);
    });

    setClients(filteredClients);

    if (text) {
      searchDatalayer({ search_term: text, success: true });
    }
  }

  function handleSearchCancel() {
    setSearchInput("");
    setClients(clientsResponse);
  }

  function toggleModal(client?: ClientProps) {
    if (client) {
      handleSetEditClient(client);
    }
    if (isOpen) {
      fetchClients();
    }
    setIsOpen(!isOpen);
  }

  return (
    <>
      {!isOpen ? (
        <>
          <S.Top>
            <S.ButtonClose onClick={() => handleToggleOpen()}>
              <FiX size={10} color={theme.colors.text} />
            </S.ButtonClose>

            <p>Clientes</p>
          </S.Top>

          <S.Content>
            <div>
              <SearchInput
                placeholder="Procure pelo nome do cliente."
                searchValue={searchInput}
                onChange={(event) => handleSearchClient(event.target.value)}
                // eslint-disable-next-line react/jsx-no-bind
                handleCancel={handleSearchCancel}
              />
            </div>

            <div>
              <Button typeButton="outline" onClick={handleSwapCreateClient}>
                Criar novo cliente
              </Button>
            </div>

            <S.ListClients>
              {clients.map((clientData) => (
                <S.ClientItem
                  key={clientData.id}
                  isSelected={Number(clientData.id) === Number(client.id)}
                >
                  <>
                    <button
                      className="select-client"
                      type="button"
                      onClick={() => handleSelectedClient(clientData)}
                    >
                      <S.Profile>
                        <span>{clientData.initialsLetterName}</span>
                      </S.Profile>
                      <p>{clientData.name}</p>
                    </button>
                  </>
                  <S.EditClientButton>
                    {" "}
                    <EditButton onClick={() => toggleModal(clientData)} />
                  </S.EditClientButton>
                </S.ClientItem>
              ))}
            </S.ListClients>
          </S.Content>
        </>
      ) : (
        <FormEditClient
          module={module}
          toggleModal={toggleModal}
          isFromList={true}
        />
      )}
    </>
  );
}
