import React, { useState } from "react";

import { QuantityCounter } from "components/QuantityCounter";
import { ModalRight } from "components/ModalRight";
import { ModalObservationDetail } from "components/ModalObservationDetail";
import { CardChecklistDetail } from "components/CardChecklistDetail";

import { ServiceDTO } from "dtos/ServiceDTO";

import * as S from "./styles";

type CardSelectedServicesProps = {
  data: ServiceDTO;
  onIncrement: (id: number, price: number) => void;
  disabled?: boolean;
};

export function CardSelectedServices({
  data,
  onIncrement,
  disabled,
}: CardSelectedServicesProps) {
  const [isOpenObservationDetail, setIsOpenObservationDetail] = useState(false);
  const [isOpenCheckListDetail, setIsOpenCheckListDetail] = useState(false);

  function handleIncrement(quantity: number) {
    if (disabled) {
      return;
    }
    if (data.idBudgetService === undefined) {
      onIncrement(data.id, quantity);
    } else {
      onIncrement(data.idBudgetService, quantity);
    }
  }

  function handleToggle() {
    setIsOpenObservationDetail(!isOpenObservationDetail);
  }

  function handleToggleModalCheckListDetail() {
    setIsOpenCheckListDetail(!isOpenCheckListDetail);
  }

  return (
    <>
      <ModalRight
        isOpen={isOpenObservationDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggle}
      >
        <ModalObservationDetail
          observation={data.description || ""}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={handleToggle}
        />
      </ModalRight>

      <ModalRight
        isOpen={isOpenCheckListDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={handleToggleModalCheckListDetail}
      >
        <CardChecklistDetail
          nameCheckList={
            data?.serviceChecklist ? data?.serviceChecklist[0]?.name : ""
          }
          checklistRegisters={
            data?.serviceChecklist ? data?.serviceChecklist[0]?.registers : []
          }
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={handleToggleModalCheckListDetail}
        />
      </ModalRight>

      <S.Container>
        <header>
          <S.Title>{data.service.name}</S.Title>
          <S.Title>{data.formattedTotal}</S.Title>
        </header>

        {data.equipment.name ? (
          <S.Description>
            {data.equipment.name}{" "}
            {!!data.equipmentType?.name && data.equipmentType.name}{" "}
            {!!data.capacity?.name && data.capacity.name}{" "}
            {!!data?.brand && ` | Marca: ${data.brand} `}
          </S.Description>
        ) : (
          <S.Description>Sem equipamento cadastrado.</S.Description>
        )}

        <S.Description>
          {!!data.runtime?.time &&
            !!data.runtime?.extension &&
            `Execução: ${data.runtime.time} ${data.runtime.extension},`}
          {!!data.warranty?.time &&
            !!data.warranty?.extension &&
            ` Garantia: ${data.warranty.time} ${data.warranty.extension} `}

          {!!data.description && (
            <>
              <br />
              <S.ObservationButton type="button" onClick={() => handleToggle()}>
                Detalhamento do Serviço
              </S.ObservationButton>
            </>
          )}

          <br />

          {data?.serviceChecklist && data?.serviceChecklist?.length > 0 && (
            <S.ObservationButton
              type="button"
              onClick={() => handleToggleModalCheckListDetail()}
            >
              Detalhamento dos Registros
            </S.ObservationButton>
          )}
        </S.Description>

        <S.WrapperContent>
          <S.TextDetail>Quantidade:</S.TextDetail>

          <QuantityCounter
            disabled={disabled}
            initialValue={data.quantity}
            // eslint-disable-next-line react/jsx-no-bind
            onIncrement={handleIncrement}
          />
        </S.WrapperContent>
        {data?.serviceChecklist && data?.serviceChecklist?.length > 0 && (
          <S.Checklist>
            <p>{data?.serviceChecklist?.length} checklist(s) vinculado(s)</p>
          </S.Checklist>
        )}
      </S.Container>
    </>
  );
}
