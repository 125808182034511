import { useServiceOrder } from "hooks/serviceOrder";
import * as S from "./styles";

import CloseIcon from "assets/icons/buttonClose.svg";

type Props = {
  report: string;
  handleToggleModal: () => void;
};

export function ModalDetailReport({ report, handleToggleModal }: Props) {
  const { currentService } = useServiceOrder();

  return (
    <>
      <S.Header>
        <div style={{ display: "flex", alignItems: "center" }}>
          <S.BackButton onClick={handleToggleModal}>
            <img src={CloseIcon} alt="Fechar" />
          </S.BackButton>

          <h4>Relatório final</h4>
        </div>

        <S.WrapperServiceName>
          <S.CardText>Serviço</S.CardText>
          <S.Card>
            <span>{currentService.service.service.name}</span>
          </S.Card>
        </S.WrapperServiceName>
      </S.Header>

      <S.CardText>Descrição</S.CardText>
      <S.ContentReport>
        <pre>{report}</pre>
      </S.ContentReport>
    </>
  );
}
