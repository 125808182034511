import { useState } from "react";

import { ModalRight } from "components/ModalRight";
import { ModalEditRight } from "components/ModalEditRight";
import { ModalClient } from "components/ModalClient";
import { ButtonModalAction } from "components/ButtonModalAction";
import { CardSelectedClient } from "components/CardSelectedClient";
import { FormEditClient } from "components/ModalPageClients/FormEditClient";
import { UnityForm } from "components/UnityForm";

import { useClient } from "hooks/budget/client";
import { useEditClient } from "hooks/editClient";
import * as S from "./styles";

type ClientAreaProps = {
  hasError?: string;
  disabled?: boolean;
  module?: "budget" | "serviceOrder" | "PMOC" | "client";
};

export function ClientArea({ hasError, disabled, module }: ClientAreaProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const { client, hasClient } = useClient();
  const { handleSetEditClient } = useEditClient();

  function handleToggleOpen() {
    if (disabled) {
      return;
    }
    handleSetEditClient(client);
    setIsOpen(!isOpen);
    setModalAddress(true);
  }

  function handleToggleListClients() {
    if (disabled) {
      return;
    }
    setIsOpen(!isOpen);
    setModalAddress(false);
  }

  function handleToggleEditClientModal() {
    setIsModalEditOpen(!isModalEditOpen);
    setShowEditModal((oldState) => !oldState);
  }

  return (
    <S.Container>
      <ModalRight isOpen={isOpen} handleToggleOpen={handleToggleOpen}>
        {modalAddress ? (
          <UnityForm
            module={module}
            defaultUnity
            handleToggleOpen={handleToggleListClients}
          />
        ) : (
          <ModalClient module={module} handleToggleOpen={handleToggleOpen} />
        )}
      </ModalRight>

      {showEditModal ? (
        <ModalEditRight
          isModalEditOpen={isModalEditOpen}
          toggleModal={handleToggleEditClientModal}
        >
          <FormEditClient
            module={module}
            toggleModal={handleToggleEditClientModal}
            isFromList={true}
          />
        </ModalEditRight>
      ) : null}

      {hasClient ? (
        <CardSelectedClient
          showClientsList={handleToggleEditClientModal}
          showModalAddress={handleToggleOpen}
          disabled={disabled}
        />
      ) : (
        <ButtonModalAction
          label="Cliente*"
          placeholder="Selecione um cliente"
          isSelected={isOpen}
          onClick={handleToggleListClients}
          hasError={hasError}
        />
      )}
    </S.Container>
  );
}
