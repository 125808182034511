import React, { FormEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import request from "axios";
import * as Yup from "yup";
import Slider from "rc-slider";
import { lighten } from "polished";
import { useTheme } from "styled-components";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";
import { ButtonCheckbox } from "components/ButtonCheckbox";
import { ModalRight } from "components/ModalRight";
import { ModalPolicyAndTermOfUse } from "components/ModalPolicyAndTermOfUse";
import { NewAlertModal } from "components/NewAlertModal";
import { ModalCompletedProfile } from "./ModalCompletedProfile";

import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";

import getValidationErrors from "utils/getValidationErrors";
import addMaskDocument from "utils/addMaskDocument";

import { getCep } from "services/cep";
import { getCNPJ } from "services/getCNPJ";

import { api } from "services/api";

import { AreasOfExpertise } from "dtos/UserDTO";

import * as S from "./styles";

type Errors = {
  [key: string]: string;
};

type RouteState = {
  before: string;
};

type ExpertiseArea = {
  id: string;
  name: string;
};

type ArrayExpertise = {
  letter: string;
  items: ExpertiseArea[];
};

const marks = {
  0: (
    <S.Marker>
      <S.MarkerLine />
      <div>0</div>
    </S.Marker>
  ),
  50: (
    <S.Marker>
      <S.MarkerLine />
      <div>50</div>
    </S.Marker>
  ),
  100: (
    <S.Marker>
      <S.MarkerLine />
      <div>100</div>
    </S.Marker>
  ),
  150: (
    <S.Marker>
      <S.MarkerLine />
      <div>150</div>
    </S.Marker>
  ),
  200: (
    <S.Marker>
      <S.MarkerLine />
      <div>200</div>
    </S.Marker>
  ),
  250: (
    <S.Marker>
      <S.MarkerLine />
      <div>250</div>
    </S.Marker>
  ),
  300: (
    <S.Marker>
      <S.MarkerLine />
      <div>300</div>
    </S.Marker>
  ),
  350: (
    <S.Marker>
      <S.MarkerLine />
      <div>350</div>
    </S.Marker>
  ),
  400: (
    <S.Marker>
      <S.MarkerLine />
      <div>400</div>
    </S.Marker>
  ),
};

type ModalPolicyAndTermOfUseProps = {
  isVisible: boolean;
  policyOrTermOfUse: "policy" | "termOfUse";
};

export function FinishRegister() {
  const { user, updateUser, signOut } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation<RouteState>();
  const theme = useTheme();

  const [name, setName] = useState("");
  const [document_cpf, setDocument_cpf] = useState("");
  const [document_cnpj, setDocument_cnpj] = useState("");
  const [documentCpfInCnpj, setDocumentCpfInCnpj] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");

  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const [complement, setComplement] = useState("");
  const [hasCPF, setHasCPF] = useState<"" | "CPF" | "CNPJ">("");

  const [neighborhood, setNeighborhood] = useState("");

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState<Errors>({});

  const [isOpenAlertModalAddCertificate, setIsOpenAlertModalAddCertificate] =
    useState(false);
  const [actuationAreaModal, setActuactionAreaModal] = useState(false);

  const [expertiseArea, setExpertiseArea] = useState<ArrayExpertise[]>([]);

  const [expertiseForm, setExpertiseForm] = useState({
    distanceExpertice: 10,
  });
  const [checkedExpertise, setCheckedExpertise] = useState<string[]>([]);
  const [areasOfExpertise, setAreasOfExpertise] = useState<AreasOfExpertise[]>(
    []
  );
  const [areasOfExpertiseLoaded, setAreasOfExpertiseLoaded] = useState<
    AreasOfExpertise[]
  >([]);
  const [acceptedExclusionTerm, setAcceptedExclusionTerm] = useState(false);
  const [modalDeleteAcountVisible, setModalDeleteAcountVisible] =
    useState(false);
  const [modalConfirmDeleteAcountVisible, setModalConfirmDeleteAcountVisible] =
    useState(false);

  const [modalPolicyAndTermOfUseProps, setModalPolicyAndTermOfUseProps] =
    useState<ModalPolicyAndTermOfUseProps>({
      isVisible: false,
    } as ModalPolicyAndTermOfUseProps);
  const [loadingButtonDeleteAcount, setLoadingButtonDeleteAcount] =
    useState(false);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.document.type === "PF") {
      if (user.document.cpf.length === 11) {
        const cpfMasked = addMaskDocument(user.document.cpf);
        setHasCPF("CPF");
        setDocument_cpf(cpfMasked);
      }
    } else {
      const cnpjMasked = addMaskDocument(user.document.cnpj);
      setHasCPF("CNPJ");
      setDocument_cnpj(cnpjMasked);
    }

    setCompanyName(user.companyName!);
    setPhone(() => {
      const dddPhone = user.phone!.slice(0, 2);
      const firstPart = user.phone!.slice(2, 7);
      const secondaryPart = user.phone!.slice(7, 11);

      return `(${dddPhone}) ${firstPart}-${secondaryPart}`;
    });

    setCep(user.address!.cep);
    setCity(user.address!.city);
    setUf(user.address!.state);
    setStreet(user.address!.street);
    setNeighborhood(user.address!.neighborhood);
    setNumber(user.address!.number);
    setComplement(user.address!.complement);
    setExpertiseForm({
      distanceExpertice: Number(user.distanceLimitActuation) || 10,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setName(user.name);
    if (user.profileApproved) {
      setCompanyName(user.companyName!);

      if (user.document.type === "PF") {
        if (user.document.cpf.length === 11) {
          const cpfMasked = addMaskDocument(user.document.cpf);
          setHasCPF("CPF");
          setDocument_cpf(cpfMasked);
        }
      } else {
        const cnpjMasked = addMaskDocument(user.document.cnpj);
        setHasCPF("CNPJ");
        setDocument_cnpj(cnpjMasked);
      }
      setCep(user.address!.cep);
      setCity(user.address!.city);
      setUf(user.address!.state);
      setStreet(user.address!.street);
      setNeighborhood(user.address!.neighborhood);
      setNumber(user.address!.number);
      setComplement(user.address!.complement);
      setPhone(() => {
        const dddPhone = user.phone!.slice(0, 2);
        const firstPart = user.phone!.slice(2, 7);
        const secondaryPart = user.phone!.slice(7, 11);

        return `(${dddPhone}) ${firstPart}-${secondaryPart}`;
      });
      setExpertiseForm({
        distanceExpertice: Number(user.distanceLimitActuation) || 10,
      });
    }
    loadExpertiseArea();

    if (user.underAnalysis && user.underAnalysis === true) {
      return addToast({
        title: "Perfil completo!",
        description: "Seu cadastro está sendo analisado pelo time Profiz",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function getUser() {
    const { data } = await api.get("/users/me");
    updateUser(data);
  }

  const loadExpertiseArea = async () => {
    try {
      const { data } = await api.get("/users/areas-of-expertise");
      const arrayToSet: ArrayExpertise[] = [];
      const letterArray: string[] = [];
      const filteredData = data.areasOfExpertise.sort(
        (a: AreasOfExpertise, b: AreasOfExpertise) =>
          a.name.localeCompare(b.name)
      );

      filteredData.forEach((item: ExpertiseArea) => {
        const letterArrayIndex = letterArray.findIndex(
          (letter) => item.name[0] === letter
        );
        if (letterArrayIndex < 0) {
          letterArray.push(item.name[0]);
          arrayToSet.push({
            letter: item.name[0],
            items: [item],
          });
        } else {
          const index = arrayToSet.findIndex(
            (setLetter) => setLetter.letter === item.name[0]
          );
          arrayToSet[index].items.push(item);
        }
      });

      // eslint-disable-next-line array-callback-return
      let expertiseArea = filteredData.map((item: AreasOfExpertise) => {
        const index = user.areasOfExpertise.findIndex((area) =>
          typeof area === "object" ? area.id === item.id : -1
        );
        if (index >= 0) {
          return item.id;
        }
      });

      // eslint-disable-next-line array-callback-return
      let expertiseAreaFull = filteredData.map((item: AreasOfExpertise) => {
        const index = user.areasOfExpertise.findIndex((area) =>
          typeof area === "object" ? area.id === item.id : -1
        );
        if (index >= 0) {
          return item;
        }
      });

      expertiseAreaFull = expertiseAreaFull.filter(
        (item: AreasOfExpertise) => typeof item !== "undefined"
      );

      setAreasOfExpertiseLoaded(filteredData);
      setAreasOfExpertise(expertiseAreaFull);
      expertiseArea = expertiseArea.filter(
        (item: string) => typeof item !== "undefined"
      );
      setExpertiseArea(arrayToSet);
      if (expertiseArea.length > 0) {
        setCheckedExpertise(expertiseArea);
      }
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao carregar as áreas de atuação.",
        type: "error",
      });
    }
  };

  const checkExpertise = (id: string, idx: number) => {
    const index = checkedExpertise.findIndex((item) => item === id);
    const expertiseIndex = areasOfExpertiseLoaded.findIndex(
      (item) => item.id === id
    );
    if (index < 0) {
      setCheckedExpertise([...checkedExpertise, id]);
      areasOfExpertise.push(areasOfExpertiseLoaded[expertiseIndex]);
    } else {
      checkedExpertise.splice(index, 1);
      setCheckedExpertise([...checkedExpertise]);
      areasOfExpertise.splice(idx, 1);
    }
    setAreasOfExpertise([...areasOfExpertise]);
  };

  async function handleSubmitingCep() {
    const formattedCep = cep.replaceAll("_", "").replace("-", "");
    if (formattedCep.length < 8) return;

    const response = await getCep(cep);

    if (response.erro) {
      return addToast({
        title: "Ops...",
        description: "Dados do endereço não encontrado.",
        type: "error",
      });
    }

    setUf(response.uf);
    setCity(response.localidade);
    setStreet(response.logradouro);
    setNeighborhood(response.bairro);
    setComplement(response.complemento);
  }

  async function handleSubmitingPJ() {
    try {
      let parsedDocumentCnpj = document_cnpj.replace(/\D/g, "");

      const response = await getCNPJ(parsedDocumentCnpj);

      if (!response?.["error"]) {
        const fantasyNameResponse: string =
          response?.["NOME FANTASIA"] || response?.["RAZAO SOCIAL"] || "";
        const telephoneResponse: string =
          `${response?.["DDD"]}${response?.["TELEFONE"]}` || "";
        const cepResponse: string = response?.["CEP"] || "";
        const streetResponse: string =
          `${response?.["TIPO LOGRADOURO"]} ${response?.["LOGRADOURO"]}` || "";
        const neighborhoodResponse: string = response?.["BAIRRO"] || "";
        const numberResponse: string = response?.["NUMERO"] || "";
        const complementResponse: string = response?.["COMPLEMENTO"] || "";
        const cityResponse: string = response?.["MUNICIPIO"] || "";
        const ufResponse: string = response?.["UF"] || "";

        setCompanyName(fantasyNameResponse);
        setPhone(telephoneResponse);
        setCep(cepResponse);
        setStreet(streetResponse);
        setNeighborhood(neighborhoodResponse);
        setNumber(numberResponse);
        setComplement(complementResponse);
        setCity(cityResponse);
        setUf(ufResponse);
      }
    } catch {
      addToast({
        title: "Ops...",
        description:
          "Não foi possível buscar as informações para o CNPJ informado.",
        type: "info",
      });
    }
  }

  function handleChooseDocument(document: "cpf" | "cnpj") {
    if (document === "cpf") {
      setHasCPF("CPF");
      setDocument_cnpj("");
      setDocumentCpfInCnpj("");
      setHasError({ ...hasError, documentCpfInCnpj: "", document_cnpj: "" });
    } else {
      setHasCPF("CNPJ");
      setDocument_cpf("");
    }
  }

  async function handleFinishRegister() {
    if (hasCPF.trim() === "") {
      addToast({
        title: "Ops...",
        description: "Selecione uma natureza jurídica",
        type: "error",
      });
      return;
    }

    if (checkedExpertise.length <= 0) {
      addToast({
        title: "Ops...",
        description: "Selecione pelo menos uma área de atuação.",
        type: "error",
      });
      return;
    }

    if (expertiseForm.distanceExpertice <= 0) {
      addToast({
        title: "Ops...",
        description: "Limitação de distância deve ser maior que 0 Km",
        type: "error",
      });
      return;
    }

    const typeSelected = hasCPF === "CPF" ? "cpf" : "cnpj";
    const currentDocument = `document_${typeSelected}`;
    setLoading(true);

    try {
      const selectDocument = {
        cpf: Yup.string()
          .required("CPF é obrigatório")
          .min(14, "Mínimo 11 dígitos"),
        cnpj: Yup.string()
          .required("CNPJ é obrigatório")
          .min(18, "Mínimo 14 dígitos"),
      };

      /*
       documentCpfInCnpj:
       Verifica se o campo de document_cnpj não existir ou estiver sem dados,
       se não tiver ele valida se o cpf(não tendo cnpj), está preenchido,
       se não tiver, sobe erro, se estiver não sobe.
       document_cnpj estiver preenchido, valida se a opção de ter ou não
       cnpj está como verdadeira, se estiver, valida se o cpf está preenchido ou não.
      */
      const schema = Yup.object().shape({
        name: Yup.string().required("Nome é obrigatório"),
        [currentDocument]: selectDocument[typeSelected],
        documentCpfInCnpj: Yup.string().when("document_cnpj", {
          is: (value: string) => !!value.length || !value.length,
          then: Yup.string().when("document_cpf", {
            is: (value: string) => !value,
            then: Yup.string()
              .required("CPF é obrigatório")
              .min(14, "Mínimo 11 dígitos"),
            otherwise: Yup.string().when("hasCPF", {
              is: (val: boolean) => val,
              then: Yup.string().when("documentCpfInCnpj", {
                is: (value: string) => value.length < 1,
                then: Yup.string()
                  .required("CPF é obrigatório")
                  .min(14, "Mínimo 11 dígitos"),
              }),
              otherwise: Yup.string(),
            }),
          }),
          otherwise: Yup.string(),
        }),
        phone: Yup.string()
          .min(14, "Mínimo 10 dígitos")
          .max(15, "Máximo 11 dígitos")
          .required("Telefone é obrigatório"),
        companyName: Yup.string().when("document_cnpj", {
          is: (val: string) => !!val.length,
          then: Yup.string().required("Campo é Obrigatório"),
          otherwise: Yup.string(),
        }),
        cep: Yup.string()
          .required("Cep é obrigatório")
          .min(8, "Mínimo 8 dígitos"),
        street: Yup.string().required("Endereço obrigatório"),
        number: Yup.string().required("Número é obrigatório"),
        neighborhood: Yup.string().required("Bairro é obrigatório"),
        city: Yup.string().required("Cidade é obrigatório"),
        uf: Yup.string()
          .required("UF é obrigatório")
          .min(2, "Mínimo 2 caracteres")
          .max(2, "Máximos 2 caracteres"),
      });

      await schema.validate(
        {
          name,
          document_cpf,
          documentCpfInCnpj,
          document_cnpj,
          phone,
          companyName,
          cep,
          street,
          neighborhood,
          number,
          city,
          uf,
        },
        { abortEarly: false }
      );
      setHasError({});

      const documentType = hasCPF === "CPF" ? "PF" : "PJ";

      const cpfSelected =
        typeSelected === "cpf" ? document_cpf : documentCpfInCnpj;
      const cpfFormatted = cpfSelected.replace(/[^0-9]/g, "");

      const cnpjFormatted = document_cnpj.replace(/[^0-9]/g, "");

      const cepFormatted = cep.replace(/[^0-9]/g, "");
      const phoneFormatted = phone.replace(/[^0-9]/g, "");

      const data = {
        name,
        companyName,
        document: {
          type: documentType,
          cpf: cpfFormatted,
          cnpj: hasCPF === "CPF" ? "" : cnpjFormatted,
        },
        phone: phoneFormatted,
        address: {
          cep: cepFormatted,
          street,
          neighborhood,
          number,
          city,
          state: uf,
          complement,
        },
        distanceLimitActuation: expertiseForm.distanceExpertice,
        areasOfExpertise: checkedExpertise,
      };

      const response = await api.put("/users/profile", data);
      updateUser(response.data);
      addToast({
        title: "Tudo certo!",
        description: "Cadastro completado com sucesso!",
        type: "success",
      });

      if (location.state && !!location.state.before) {
        await api.get("/verifyUserProfile");
        setIsOpenAlertModalAddCertificate(true);
        return;
      }
      setIsOpenAlertModalAddCertificate(true);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);

        addToast({
          title: "Ops!!",
          description: "Preencha todos os campos obrigatórios",
          type: "info",
        });

        return;
      }

      if (request.isAxiosError(error) && error.response?.status === 401) {
        if (error.response?.data.details.certificadoAprovado) {
          setIsOpenAlertModalAddCertificate(true);
          return;
        }

        if (error.response?.data.details.certificadoPendente) {
          setIsOpenAlertModalAddCertificate(true);
          return;
        }

        if (
          !error.response?.data.details.certificadoPendente &&
          !error.response?.data.details.certificadoAprovado
        ) {
          setIsOpenAlertModalAddCertificate(true);
          return;
        }

        history.push("/");
        return;
      }

      addToast({
        title: "Ocorreu um erro ao atualizar o cadastro",
        description: `${
          error.response?.data ? error.response?.data.error : ""
        }`,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const getChecked = (id: string) => {
    return checkedExpertise.findIndex((item) => item === id);
  };

  async function handleDeleteAccount() {
    try {
      setLoadingButtonDeleteAcount(true);
      await api.delete("/users/me");
      setModalDeleteAcountVisible(false);

      signOut();
      addToast({
        title: "Sucesso!!",
        description:
          "Pedido de exclusão de conta está sendo analisado pelo time Profiz.",
        type: "success",
      });
    } catch (err) {
      addToast({
        title: "Ops!!",
        description:
          request.isAxiosError(err) && err.response?.data.error
            ? err.response.data.error
            : "Ocorreu um erro ao deletar a conta.",
        type: "error",
      });
    } finally {
      setModalConfirmDeleteAcountVisible(false);
      setAcceptedExclusionTerm(false);
      setLoadingButtonDeleteAcount(false);
    }
  }

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <S.Container>
      <ModalRight
        isOpen={actuationAreaModal}
        handleToggleOpen={() => setActuactionAreaModal(false)}
      >
        <div style={{ marginBottom: 20 }}>
          <ArrowButton
            handleFunction={() => {
              setActuactionAreaModal(false);
            }}
          />
        </div>
        <S.TextContainer style={{ marginTop: 0 }}>
          <S.BolderText style={{ color: theme.colors.text_light_87 }}>
            Áreas de atuação
          </S.BolderText>
          <S.Text style={{ color: theme.colors.text_light_87 }}>
            Você poderá receber oportunidades de serviço de acordo as áreas
            selecionadas
          </S.Text>
          <S.BolderText
            style={{
              color: theme.colors.text_light_87,
              fontSize: 14,
              marginTop: 20,
            }}
          >
            Selecione todas suas áreas de atuação
          </S.BolderText>
        </S.TextContainer>
        <S.ExpertiseAreaContainer>
          {expertiseArea.map((item) => {
            return (
              <div key={item.letter}>
                <S.Text
                  style={{
                    color: theme.colors.primary,
                  }}
                >
                  {item.letter}
                </S.Text>
                <S.Divider />
                {item.items.map((expertise: ExpertiseArea) => (
                  <div key={expertise.id}>
                    <S.ExpertiseContainer>
                      <S.Checkbox
                        onClick={() => checkExpertise(expertise.id, -1)}
                      >
                        {getChecked(expertise.id) >= 0 && <S.Check />}
                      </S.Checkbox>
                      <S.Text
                        style={{
                          marginLeft: 10,
                          fontSize: 12,
                          marginTop: 0,
                          color: theme.colors.text_light_87,
                        }}
                      >
                        {expertise.name}
                      </S.Text>
                    </S.ExpertiseContainer>
                    <S.Divider />
                  </div>
                ))}
              </div>
            );
          })}
        </S.ExpertiseAreaContainer>
        <Button
          style={{ marginTop: "30px" }}
          typeButton="default"
          disabled={checkedExpertise.length === 0}
          onClick={() => setActuactionAreaModal(false)}
        >
          Salvar
        </Button>
      </ModalRight>

      <ModalRight
        isOpen={modalDeleteAcountVisible}
        handleToggleOpen={() => {
          setAcceptedExclusionTerm(false);
          setModalDeleteAcountVisible(false);
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <ArrowButton
            handleFunction={() => {
              setAcceptedExclusionTerm(false);
              setModalDeleteAcountVisible(false);
            }}
          />
        </div>
        <S.TextContainer style={{ marginTop: 0 }}>
          <S.BolderText>Exclusão de conta Profiz</S.BolderText>
          <S.Text>
            Na Profiz você tem controle dos seus dados e pode solicitar a
            exclusão de sua conta a qualquer momento.
          </S.Text>
          <S.Text>
            Ao realizar a solicitação, o time de atendimento Profiz irá realizar
            a exclusão dos dados e dentro de poucos dias o acesso ao app e
            demais áreas do ecossistema Profiz serão excluídos.
          </S.Text>
          <S.Text>
            Você poderá criar uma nova conta no futuro, mas os dados excluídos
            não poderão ser recuperados.
          </S.Text>
          <S.Text>
            Para mais informações acesse nossa política de privacidade pelo link
            abaixo.
          </S.Text>
          <p
            onClick={() =>
              setModalPolicyAndTermOfUseProps({
                isVisible: true,
                policyOrTermOfUse: "policy",
              })
            }
          >
            <S.Text style={{ color: theme.colors.primary, cursor: "pointer" }}>
              Acessar política de privacidade
            </S.Text>
          </p>

          <S.BolderText style={{ marginTop: 30 }}>
            Esta ação será irreversivel! Deseja prosseguir?
          </S.BolderText>
        </S.TextContainer>

        <S.AcceptView>
          <S.Checkbox
            onClick={() => setAcceptedExclusionTerm(!acceptedExclusionTerm)}
          >
            {acceptedExclusionTerm && <S.Check />}
          </S.Checkbox>

          <S.Text
            style={{
              marginLeft: 10,
              fontSize: 12,
              marginTop: 0,
              color: theme.colors.text_light_87,
            }}
          >
            Estou de acordo com o processo de exclusão de dados.
          </S.Text>
        </S.AcceptView>
        <Button
          typeButton={
            !acceptedExclusionTerm || loadingButtonDeleteAcount
              ? "default"
              : "delete"
          }
          disabled={!acceptedExclusionTerm || loadingButtonDeleteAcount}
          loading={loadingButtonDeleteAcount}
          onClick={() => setModalConfirmDeleteAcountVisible(true)}
        >
          Excluir conta Profiz
        </Button>

        <NewAlertModal
          isVisible={modalConfirmDeleteAcountVisible}
          title="Aviso!"
          description="Deseja realmente excluir a sua conta?"
          highlightedDescription="Lembre-se, não será possivel reverter a solicitação"
          action="choose"
          handleConfirm={handleDeleteAccount}
          onCloseModal={() => {
            setAcceptedExclusionTerm(!acceptedExclusionTerm);
            setModalConfirmDeleteAcountVisible(false);
          }}
          labelConfirm="Confirmar"
          labelCancel="Fechar"
          buttonConfirmColor="#FA8B94"
        />
      </ModalRight>

      {modalPolicyAndTermOfUseProps.isVisible && (
        <ModalPolicyAndTermOfUse
          isVisible={modalPolicyAndTermOfUseProps.isVisible}
          policyOrTermOfUse={modalPolicyAndTermOfUseProps.policyOrTermOfUse}
          onCloseModal={() =>
            setModalPolicyAndTermOfUseProps({
              isVisible: false,
            } as ModalPolicyAndTermOfUseProps)
          }
        />
      )}

      <ModalCompletedProfile
        isVisible={isOpenAlertModalAddCertificate}
        setIsActive={setIsOpenAlertModalAddCertificate}
      />

      <header>
        <ArrowButton />

        <div>
          <h2>Complete seu perfil</h2>
          <p>
            Preencha as informações abaixo para completar seu cadastro e começar
            a aproveitar o app.
          </p>
        </div>
      </header>

      <form onSubmit={handleSubmitForm} id="form_cadastro_completo">
        <S.WrapperInput>
          <Input
            disabled={user.underAnalysis && user.underAnalysis === true}
            name="Nome*"
            placeholder="Preencha com seu nome completo"
            value={name}
            onChange={(e) => setName(e.target.value.trimStart())}
            hasError={hasError.name}
            onFocusClearError={() => setHasError({ ...hasError, name: "" })}
          />
        </S.WrapperInput>

        <S.WrapperCheckBox>
          <ButtonCheckbox
            label="Natureza jurídica*"
            placeholder="Pessoa jurídica"
            isChecked={hasCPF === "CNPJ"}
            onClick={() => handleChooseDocument("cnpj")}
            disabled={user.underAnalysis && user.underAnalysis === true}
          />

          <ButtonCheckbox
            placeholder="Não possuo CNPJ"
            isChecked={hasCPF === "CPF"}
            onClick={() => handleChooseDocument("cpf")}
            disabled={user.underAnalysis && user.underAnalysis === true}
          />
        </S.WrapperCheckBox>

        {hasCPF === "CNPJ" && (
          <>
            <S.WrapperInput>
              <Input
                disabled={
                  (user.underAnalysis && user.underAnalysis === true) ||
                  hasCPF !== "CNPJ"
                }
                style={hasCPF !== "CNPJ" ? { opacity: 0.5 } : {}}
                name="CNPJ*"
                placeholder="Preencha com seu CNPJ"
                maskType="cnpj"
                value={document_cnpj}
                onChange={(e) => setDocument_cnpj(e.target.value)}
                hasError={hasError.document_cnpj}
                onFocusClearError={() =>
                  setHasError({ ...hasError, document_cnpj: "" })
                }
                handleEditingBlur={handleSubmitingPJ}
              />
            </S.WrapperInput>

            <S.WrapperInput>
              <Input
                disabled={user.underAnalysis && user.underAnalysis === true}
                name="Nome da empresa"
                placeholder="Preencha com o nome da empresa"
                value={companyName}
                onChange={(e) =>
                  e.target.value.length <= 64 &&
                  setCompanyName(e.target.value.trimStart())
                }
                hasError={hasError.companyName}
                onFocusClearError={() =>
                  setHasError({ ...hasError, companyName: "" })
                }
              />
            </S.WrapperInput>

            <S.WrapperInput>
              <Input
                name="Seu CPF*"
                placeholder="Preencha com seu CPF"
                maskType="cpf"
                value={documentCpfInCnpj}
                onChange={(e) => setDocumentCpfInCnpj(e.target.value)}
                hasError={hasError.documentCpfInCnpj}
                onFocusClearError={() =>
                  setHasError({ ...hasError, documentCpfInCnpj: "" })
                }
              />
            </S.WrapperInput>
          </>
        )}

        {hasCPF === "CPF" && (
          <>
            <S.WrapperInput>
              <Input
                disabled={user.underAnalysis && user.underAnalysis === true}
                name="Nome da empresa"
                placeholder="Preencha com o nome da empresa"
                value={companyName}
                onChange={(e) =>
                  e.target.value.length <= 64 &&
                  setCompanyName(e.target.value.trimStart())
                }
                hasError={hasError.companyName}
                onFocusClearError={() =>
                  setHasError({ ...hasError, companyName: "" })
                }
              />
            </S.WrapperInput>

            <S.WrapperInput>
              <Input
                disabled={user.underAnalysis && user.underAnalysis === true}
                name="Seu CPF*"
                placeholder="Preencha com seu CPF"
                maskType="cpf"
                value={document_cpf}
                onChange={(e) => setDocument_cpf(e.target.value)}
                hasError={hasError.document_cpf}
                onFocusClearError={() =>
                  setHasError({ ...hasError, document_cpf: "" })
                }
              />
            </S.WrapperInput>
          </>
        )}

        <S.WrapperInput>
          <Input
            disabled={user.underAnalysis && user.underAnalysis === true}
            name="Celular / WhatsApp com DDD*"
            placeholder="Preencha com seu telefone"
            maskType="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            hasError={hasError.phone}
            onFocusClearError={() => setHasError({ ...hasError, phone: "" })}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            disabled={user.underAnalysis && user.underAnalysis === true}
            name="CEP*"
            placeholder="Preencha com seu CEP"
            maskType="zip-code"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
            hasError={hasError.cep}
            onFocusClearError={() => setHasError({ ...hasError, cep: "" })}
            handleEditingBlur={handleSubmitingCep}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            disabled={user.underAnalysis && user.underAnalysis === true}
            name="Rua*"
            placeholder="Nome da rua"
            value={street}
            onChange={(e) => setStreet(e.target.value.trimStart())}
            hasError={hasError.street}
            onFocusClearError={() => setHasError({ ...hasError, street: "" })}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            disabled={user.underAnalysis && user.underAnalysis === true}
            name="Bairro*"
            placeholder="Preencha com seu bairro"
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value.trimStart())}
            hasError={hasError.neighborhood}
            onFocusClearError={() =>
              setHasError({ ...hasError, neighborhood: "" })
            }
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            disabled={user.underAnalysis && user.underAnalysis === true}
            name="Número*"
            type="text"
            placeholder="Ex: 123"
            value={number}
            onChange={(e) => setNumber(e.target.value.trimStart())}
            hasError={hasError.number}
            onFocusClearError={() => setHasError({ ...hasError, number: "" })}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <Input
            disabled={user.underAnalysis && user.underAnalysis === true}
            name="Complemento"
            placeholder="Ex: Apto. 999"
            value={complement}
            onChange={(e) => setComplement(e.target.value.trimStart())}
            hasError={hasError.complement}
          />
        </S.WrapperInput>

        <S.GroupInputs>
          <div>
            <Input
              disabled={user.underAnalysis && user.underAnalysis === true}
              name="Cidade*"
              placeholder="Preencha com sua cidade"
              value={city}
              onChange={(e) => setCity(e.target.value.trimStart())}
              hasError={hasError.city}
              onFocusClearError={() => setHasError({ ...hasError, city: "" })}
            />
          </div>

          <div>
            <Input
              disabled={user.underAnalysis && user.underAnalysis === true}
              name="UF*"
              maxLength={2}
              placeholder="Preencha com seu estado"
              value={uf}
              onChange={(e) => setUf(e.target.value.trimStart().toUpperCase())}
              hasError={hasError.uf}
              onFocusClearError={() => setHasError({ ...hasError, uf: "" })}
            />
          </div>
        </S.GroupInputs>

        <S.Title style={{ fontSize: 14, marginLeft: 0, marginBottom: 10 }}>
          Áreas de atuação*
        </S.Title>
        {areasOfExpertise
          .filter((item) => typeof item !== "undefined")
          .map((item, index) => {
            if (typeof item === "object") {
              return (
                <S.Wrapper style={{ marginBottom: 10 }} key={item.id}>
                  {item.name}
                  <div
                    onClick={() =>
                      checkedExpertise.length > 1 &&
                      !user.underAnalysis &&
                      checkExpertise(item.id, index)
                    }
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                  >
                    X
                  </div>
                </S.Wrapper>
              );
            } else {
              return <></>;
            }
          })}
        <S.AddAreaOfExpertise>
          <Button
            disabled={user.underAnalysis}
            onClick={() => setActuactionAreaModal(true)}
            typeButton="textOnly"
            style={{
              background: `${lighten(0.05, theme.colors.background)}`,
              width: 40,
              height: 40,
              marginTop: 0,
            }}
          >
            +
          </Button>
          <S.AddAreaText>Adicionar áreas de atuação</S.AddAreaText>
        </S.AddAreaOfExpertise>

        <S.Title
          style={{
            fontSize: 14,
            marginLeft: 0,
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          Limitação de distância por km*
          <p>
            Defina sua área de prestação de serviços com base no seu endereço.
          </p>
        </S.Title>

        <S.MettersContainer>
          <Button
            disabled={user.underAnalysis}
            typeButton="textOnly"
            style={{
              background: `${lighten(0.05, theme.colors.background)}`,
              width: 40,
              height: 40,
            }}
            onClick={() =>
              setExpertiseForm({
                distanceExpertice:
                  expertiseForm.distanceExpertice < 5
                    ? 0
                    : expertiseForm.distanceExpertice - 5,
              })
            }
          >
            -
          </Button>
          <S.Text
            style={{
              color: theme.colors.text_light_87,
              marginRight: 10,
              marginLeft: 10,
              marginTop: 15,
            }}
          >
            {expertiseForm.distanceExpertice} Km
          </S.Text>
          <Button
            disabled={user.underAnalysis}
            typeButton="textOnly"
            style={{
              background: `${lighten(0.05, theme.colors.background)}`,
              width: 40,
              height: 40,
            }}
            onClick={() =>
              setExpertiseForm({
                distanceExpertice:
                  expertiseForm.distanceExpertice > 395
                    ? 400
                    : expertiseForm.distanceExpertice + 5,
              })
            }
          >
            +
          </Button>
        </S.MettersContainer>
        <Slider
          disabled={user.underAnalysis}
          marks={marks}
          trackStyle={{ background: theme.colors.pagination_item }}
          railStyle={{ background: theme.colors.pagination_item }}
          handleStyle={{
            opacity: 1,
            background: theme.colors.pagination_item,
            borderColor: theme.colors.primary,
            boxShadow: "none",
          }}
          dotStyle={{ display: "none" }}
          min={0}
          value={expertiseForm.distanceExpertice}
          max={400}
          onChange={(event) =>
            setExpertiseForm({
              ...expertiseForm,
              distanceExpertice: typeof event === "number" ? event : 0,
            })
          }
          defaultValue={expertiseForm.distanceExpertice}
        />

        <S.WrapperButton>
          <Button
            disabled={!!user.underAnalysis || !!user.deletionRequest}
            loading={loading}
            onClick={() => handleFinishRegister()}
          >
            Finalizar cadastro
          </Button>

          <Button typeButton="outline" onClick={() => history.push("/")}>
            Mais tarde
          </Button>

          <Button
            typeButton="textOnly"
            style={{ color: theme.colors.text }}
            disabled={user.deletionRequest ? true : false}
            onClick={() => setModalDeleteAcountVisible(true)}
          >
            Excluir conta Profiz
          </Button>
        </S.WrapperButton>
      </form>
    </S.Container>
  );
}
