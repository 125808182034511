/* eslint import/order: 0 */
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useHistory, useParams } from "react-router";
import { FiChevronLeft } from "react-icons/fi";
import { format } from "date-fns";

import { useToast } from "hooks/toast";

import { LoadingProfiz } from "components/LoadingProfiz";
import { ContentBox } from "components/ContentBox";
import { ButtonSVG } from "components/ButtonSVG";
import { Button } from "components/Button";
import { ModalRight } from "components/ModalRight";
import { ModalObservationDetail } from "components/ModalObservationDetail";
import { CardChecklistDetail } from "components/CardChecklistDetail";

import {
  ClientProps,
  ServiceProps,
  LocalChecklistProps,
  MaterialProps,
  ServiceOrderDetailProps,
} from "dtos/ServiceOrderDetailDTO";
import { RegisterProps } from "dtos/ServiceChecklistDTO";

import CheckSvg from "assets/icons/check.svg";

import * as S from "./styles";
import apiv2 from "services/apiv2";
import { CalendarButton } from "components/CalendarButton";
import { UnityDTO } from "dtos/ClientDTO";
import { api } from "services/api";
import { ShowWhenHavePermission } from "components/Permission";
import { useAccount } from "hooks/permission/account";

type RouteParams = {
  id: string;
};

export type MappedServiceProps = {
  hour: string[];
} & ServiceProps;

type UnitiesResponse = {
  unities: UnityDTO[];
};

type CardChecklistDetailProps = {
  nameCheckList: string;
  checklistRegisters: RegisterProps[];
};

export function Scheduled() {
  const { id } = useParams<RouteParams>();

  const theme = useTheme();
  const history = useHistory();
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const [isLoading, setIsLoading] = useState(true);

  const [sequenceId, setSequenceId] = useState<number | null>(null);

  const [clientInServiceOrder, setClientInServiceOrder] = useState(
    {} as ClientProps
  );

  const [unityClient, setUnityClient] = useState({} as UnityDTO);

  const [isOpenObservationDetail, setIsOpenObservationDetail] = useState("");
  const [servicesInServiceOrder, setServicesInServiceOrder] = useState<
    MappedServiceProps[]
  >([]);

  const [checklistInServiceOrder, setChecklistInServiceOrder] = useState<
    LocalChecklistProps[]
  >([]);

  const [materialsInServiceOrder, setMaterialsInServiceOrder] = useState<
    MaterialProps[]
  >([]);

  const [observationInServiceOrder, setObservationInServiceOrder] =
    useState("");
  const [formattedCreationDate, setFormattedCreationDate] = useState("");

  const [addressNotExists, setAddressNotExists] = useState(false);

  const [isOpenCheckListDetail, setIsOpenCheckListDetail] =
    useState<CardChecklistDetailProps>({} as CardChecklistDetailProps);

  async function loadUnityData(clientResponse: ClientProps) {
    try {
      if (
        clientResponse.address?.id?.toString() === "0" ||
        !clientResponse.address?.postalCode
      ) {
        return setAddressNotExists(true);
      }
      if (clientResponse?.address && clientResponse?.address?.id) {
        clientResponse.address &&
          setUnityClient(clientResponse?.address as UnityDTO);
        return;
      }

      const response = await api.get<UnitiesResponse>(
        `/clients/${clientResponse.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: clientResponse.id,
          },
        }
      );

      const defaultUnity = response.data.unities.find((unity) => unity.default);

      if (response.data.unities.length < 1 || !defaultUnity) {
        setAddressNotExists(true);
        return;
      }

      defaultUnity && setUnityClient(defaultUnity);
    } catch {
      addToast({
        title: "Opss",
        description:
          "Ocorreu um erro ao carregar o endereço do cliente, tente novamente.",
        type: "error",
      });
    }
  }

  function handleToggleModalObservationDetail(observationDetail: string) {
    setIsOpenObservationDetail(observationDetail);
  }

  function handleToggleModalCheckListDetail({
    nameCheckList,
    checklistRegisters,
  }: CardChecklistDetailProps) {
    setIsOpenCheckListDetail({ nameCheckList, checklistRegisters });
  }

  useEffect(() => {
    async function getServiceOrderDetails() {
      try {
        const { data } = await apiv2.get(`/budgets/service-order/${id}`, {
          params: {
            accountId: whoami?.id,
          },
        });

        const {
          sequenceNumber,
          createdAt,
          client,
          services,
          localChecklists,
          materials,
          observations,
        }: ServiceOrderDetailProps = data;

        setSequenceId(sequenceNumber);

        const formattedDate = format(new Date(createdAt), "dd/MM/yyyy");
        setFormattedCreationDate(formattedDate);

        const formattedServices = getServicesMapped(services);

        setClientInServiceOrder(client);

        await loadUnityData(client);
        setServicesInServiceOrder(formattedServices);
        setChecklistInServiceOrder(localChecklists);
        setMaterialsInServiceOrder(materials);
        setObservationInServiceOrder(observations);
      } catch (error) {
        addToast({
          type: "error",
          title: "Ops...",
          description: "Erro ao buscar detalhes da ordem de serviço",
        });
      } finally {
        setIsLoading(false);
      }
    }

    getServiceOrderDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getServicesMapped(servicesParam: ServiceProps[]) {
    const mappedServices = servicesParam.map((currentService) => {
      const formattedScheduleDate = !!currentService.scheduleDate
        ? format(new Date(currentService.scheduleDate), "dd/MM/yyyy - HH:mm")
        : "";

      return {
        ...currentService,
        hour: currentService?.scheduleDate.split(" "),
        scheduleDate: formattedScheduleDate,
      };
    });

    return mappedServices;
  }

  function handleGoBack() {
    history.push("/service-order", {
      before: "ServiceOrderPendingDetail",
    });
  }

  async function handleDearchiveServiceOrder() {
    try {
      setIsLoading(true);

      await apiv2.put(`/budgets/service-order/${id}/dearchive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "Ordem de serviço desarquivada",
        description: "",
      });

      history.push(`/service-order/scheduled/${id}`);
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao desarquivar ordem de serviço",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ModalRight
        isOpen={!!isOpenObservationDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => handleToggleModalObservationDetail("")}
      >
        <ModalObservationDetail
          observation={isOpenObservationDetail}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() => handleToggleModalObservationDetail("")}
        />
      </ModalRight>

      <ModalRight
        isOpen={!!isOpenCheckListDetail?.nameCheckList}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() =>
          handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
        }
      >
        <CardChecklistDetail
          nameCheckList={
            isOpenCheckListDetail?.nameCheckList
              ? isOpenCheckListDetail?.nameCheckList
              : ""
          }
          checklistRegisters={
            isOpenCheckListDetail?.checklistRegisters
              ? isOpenCheckListDetail?.checklistRegisters
              : []
          }
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() =>
            handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
          }
        />
      </ModalRight>

      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <S.Top>
            <S.ButtonClose onClick={() => handleGoBack()}>
              <FiChevronLeft size={10} color={theme.colors.title} />
            </S.ButtonClose>

            <div>
              <h4>Ordem de Serviço {sequenceId}</h4>
              <S.WrapperArchivedText>
                <p>Ordem de serviço arquivada</p>
              </S.WrapperArchivedText>
            </div>
          </S.Top>

          <S.WrapperDate>
            <span>Data de Criação: {formattedCreationDate}</span>

            <div>
              <div />
              <span>Em andamento</span>
            </div>
          </S.WrapperDate>

          <S.Content>
            <div>
              <span>Cliente:</span>

              <ContentBox>
                <S.ContentBoxTitle>
                  {clientInServiceOrder.name}
                </S.ContentBoxTitle>
                <S.ContentBoxText>
                  {clientInServiceOrder.cpf_cnpj}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {clientInServiceOrder.phone}
                </S.ContentBoxText>
                <S.ContentBoxText>
                  {clientInServiceOrder.email}
                </S.ContentBoxText>
              </ContentBox>
            </div>

            <div>
              <ContentBox title="Endereço">
                {addressNotExists ? (
                  <S.ContentBoxText>Sem endereço cadastrado.</S.ContentBoxText>
                ) : (
                  <>
                    <S.ContentBoxTitle>
                      {unityClient.name}{" "}
                      {unityClient.default ? "(principal)" : "(adicional)"}
                    </S.ContentBoxTitle>

                    <S.ContentBoxText>
                      {unityClient!.street}, {unityClient!.number}{" "}
                      {unityClient!.district
                        ? " , " + unityClient!.district
                        : ""}
                    </S.ContentBoxText>
                    <S.ContentBoxText>
                      {unityClient!.city}, {unityClient!.uf}
                    </S.ContentBoxText>
                    <S.ContentBoxText>
                      {unityClient!.complement}
                    </S.ContentBoxText>
                  </>
                )}
              </ContentBox>
            </div>

            {servicesInServiceOrder.length > 0 && (
              <div>
                <S.ServicesTitle>Serviços:</S.ServicesTitle>

                {servicesInServiceOrder.map((service, index) => {
                  const {
                    service: serviceData,
                    quantity,
                    scheduleDate,
                    status,
                    idBudgetService,
                  } = service;

                  return (
                    <>
                      <ContentBox key={idBudgetService}>
                        <S.WrapperContentService>
                          <div>
                            <span>
                              {serviceData.service.name} ({quantity})
                            </span>

                            {!!scheduleDate && status === "concluded" && (
                              <div>
                                <S.BadgeStatus color="light" />
                                <S.ServiceScheduleStatusText>
                                  Concluído em: {scheduleDate}
                                </S.ServiceScheduleStatusText>
                              </div>
                            )}

                            {!!scheduleDate && status === "scheduled" && (
                              <div>
                                <S.BadgeStatus color="light" />
                                <S.ServiceScheduleStatusText>
                                  Agendado para: {scheduleDate}
                                </S.ServiceScheduleStatusText>
                              </div>
                            )}

                            {!scheduleDate && status !== "concluded" && (
                              <div>
                                <S.BadgeStatus color="error" />

                                <S.ServiceScheduleStatusText>
                                  Não agendado
                                </S.ServiceScheduleStatusText>
                              </div>
                            )}
                          </div>

                          {status === "concluded" ? (
                            !!scheduleDate && (
                              <S.CheckIconImage src={CheckSvg} alt="check" />
                            )
                          ) : (
                            <CalendarButton
                              openModalCalendar={false}
                              onToggleModalCalendar={() => {}}
                              handleSelectedDate={() => {}}
                              disabled={true}
                              onDeleteServiceSchedule={() => {}}
                            />
                          )}
                        </S.WrapperContentService>
                        <span>{serviceData.equipment.name}</span>&nbsp;
                        {serviceData.equipmentType?.name ? (
                          <span>{serviceData.equipmentType.name}</span>
                        ) : (
                          <span>Serviço sem equipamento</span>
                        )}
                        {serviceData.capacity?.name && (
                          <span> {serviceData.capacity.name}</span>
                        )}
                        {serviceData?.brand && (
                          <span>&ensp;| Marca: {serviceData.brand}</span>
                        )}
                        {serviceData.runtime?.time && (
                          <S.ContentBoxText>
                            Execução: {serviceData.runtime.time}{" "}
                            {serviceData.runtime.extension}
                          </S.ContentBoxText>
                        )}
                        {serviceData.warranty?.time && (
                          <S.ContentBoxText>
                            Garantia: {serviceData.warranty.time}{" "}
                            {serviceData.warranty.extension}
                          </S.ContentBoxText>
                        )}
                        {serviceData?.description && (
                          <>
                            <br />
                            <S.ObservationButton
                              type="button"
                              onClick={() =>
                                handleToggleModalObservationDetail(
                                  serviceData.description
                                )
                              }
                            >
                              Detalhamento do Serviço
                            </S.ObservationButton>
                          </>
                        )}
                        {serviceData?.serviceChecklist &&
                          serviceData?.serviceChecklist.length > 0 && (
                            <S.ObservationButton
                              type="button"
                              onClick={() =>
                                handleToggleModalCheckListDetail({
                                  nameCheckList:
                                    serviceData?.serviceChecklist![0]?.name,
                                  checklistRegisters:
                                    serviceData?.serviceChecklist![0]
                                      ?.registers,
                                })
                              }
                            >
                              Detalhamento dos Registros
                            </S.ObservationButton>
                          )}
                      </ContentBox>
                      {index !== servicesInServiceOrder.length - 1 && (
                        <S.Separator />
                      )}
                    </>
                  );
                })}
              </div>
            )}

            {checklistInServiceOrder.length > 0 && (
              <div>
                <span>Itens adicionais:</span>

                <ContentBox>
                  {checklistInServiceOrder.map((checklist) => {
                    const {
                      checklist: checklistData,
                      quantity,
                      type,
                    } = checklist;

                    const status = checklistData?.status.find(
                      (sts) => sts.type === type
                    );

                    return (
                      <div key={checklistData.id}>
                        <S.ContentBoxText>
                          <span style={{ color: theme.colors.title }}>
                            {checklistData.name}:
                          </span>{" "}
                          &nbsp;
                          {status?.label} &nbsp;
                          {type === "my_responsibility" && (
                            <span>({quantity})</span>
                          )}
                        </S.ContentBoxText>
                      </div>
                    );
                  })}
                </ContentBox>
              </div>
            )}

            {materialsInServiceOrder.length > 0 && (
              <div>
                <span>Materiais:</span>

                <ContentBox>
                  {materialsInServiceOrder.map((material, index) => {
                    const { material: materialData, quantity } = material;

                    return (
                      <>
                        <div key={materialData.id}>
                          <S.ContentBoxText>
                            {materialData.item.name} &nbsp; ({quantity})
                          </S.ContentBoxText>
                          <S.ContentBoxText>
                            <span style={{ color: theme.colors.title }}>
                              Tipo:
                            </span>{" "}
                            {materialData.type.name} &nbsp;
                          </S.ContentBoxText>
                        </div>
                        {index !== materialsInServiceOrder.length - 1 && (
                          <div style={{ padding: "5px 0" }} />
                        )}
                      </>
                    );
                  })}
                </ContentBox>
              </div>
            )}

            {!!observationInServiceOrder && (
              <div>
                <span>Detalhamento do Serviço:</span>

                <ContentBox>
                  <S.ContentBoxObservationText>
                    {observationInServiceOrder}
                  </S.ContentBoxObservationText>
                </ContentBox>
              </div>
            )}

            <S.WrapperButton>
              <Button typeButton="disabled" onClick={() => {}} disabled={true}>
                Registrar serviços
              </Button>
            </S.WrapperButton>
          </S.Content>

          <S.Footer>
            <ButtonSVG title="Download PDF" typeSvg="pdfDisabled" />
          </S.Footer>

          <S.WrapperArchiveButton>
            <ShowWhenHavePermission
              moduleHash="budgets-service-order"
              actionHash="edit-unarchive"
            >
              <Button
                typeButton="textOnly"
                onClick={handleDearchiveServiceOrder}
              >
                Desarquivar ordem de serviço
              </Button>
            </ShowWhenHavePermission>
          </S.WrapperArchiveButton>
        </S.Container>
      )}
    </>
  );
}
