import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";

type CardTeam = {
  radius?: number;
};

const customMedia = generateMedia({
  medium: "700px",
  small: "368px",
  xsmall: "294px",
});

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    min-width: 368px;
    margin-bottom: 50px;

    ${customMedia.lessThan("medium")`
      ${css`
        padding-top: 20px;
        padding-bottom: 150px;
      `}
    `}
  `}
`;

export const ProfizLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  margin-bottom: 10px;
  ${customMedia.lessThan("xsmall")`
      display: none;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: ${theme.font.bold};
      color: ${theme.colors.primary};
      padding: 10px;
    }
    .subtitle {
      font-size: 12px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.text_light_60};
      padding: 10px;
      text-align: center;
    }
  `}
`;

export const TeamCard = styled.div`
  ${({ theme }) => css`
    overflow-y: scroll;
    margin-top: 30px;
    width: 36%;
    max-height: 93vh;
    overflow-x: auto;
    border-radius: 15px;
    justify-content: center;
    padding: 0px 10px;
    ${customMedia.lessThan("medium")`
      width: 100%;
      margin-top: 0px;
      background-color: #121212;
    `}
  `}
`;

export const WrapperInput = styled.div`
  margin-bottom: 14px;
  padding: 0px 15px;
`;

export const WrapperInputPassword = styled.form`
  position: relative;
  margin-bottom: 20px;
  padding: 0px 15px;
`;

export const CardTeamInfo = styled.div<CardTeam>`
  ${({ theme, radius }) => css`
    display: flex;
    flex-direction: column;
    .title {
      font-size: 12px;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.text_light_87};
      margin-bottom: 5px;
    }
    .card-info {
      display: flex;
      flex-direction: row;
      border-radius: 10px;

      align-items: center;
      padding: 0px 10px;
      justify-content: space-between;

      height: ${window.screen.height * 0.05}px;
      background: ${theme.colors.background_modal};

      ${customMedia.lessThan("medium")`
        height: ${window.screen.height * 0.06}px;
      `};

      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
          border-radius: ${radius ? 8 : 20}px;
        }
        .info {
          margin-left: 10px;
          font-size: 12px;
          font-weight: ${theme.font.bold};
          color: ${theme.colors.text_light_87};
        }
      }
      .status {
        font-size: 12px;
        font-weight: ${theme.font.normal};
        color: ${theme.colors.text_light_60};
      }
    }
  `}
`;

export const TitlePermissions = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: ${theme.font.normal};
    color: ${theme.colors.text_light_87};
    margin-bottom: 5px;
  `}
`;

export const CardPermissions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .title {
      font-size: 12px;
      font-weight: ${theme.font.bold};
      color: ${theme.colors.text_light_87};
      margin-bottom: 5px;
    }
    .card-info {
      display: flex;
      flex-direction: row;
      border-radius: 10px;

      margin-bottom: 5px;

      align-items: center;
      padding: 0px 10px;
      justify-content: space-between;

      height: ${window.screen.height * 0.05}px;
      background: ${theme.colors.background_modal};

      ${customMedia.lessThan("medium")`
        height: ${window.screen.height * 0.06}px;
      `};

      .info {
        margin-left: 10px;
        font-size: 12px;
        font-weight: ${theme.font.bold};
        color: ${theme.colors.text_light_60};
      }

      img {
        width: 35px;
        height: 35px;
        border-radius: 8px;
      }
    }
  `}
`;
