import React from "react";

import arrowLeft from "assets/icons/arrow-left.svg";
import runIcon from "assets/icons/service-checklists-icons/runIcon.svg";
import checkIcon from "assets/icons/service-checklists-icons/checkIcon.svg";
import measureIcon from "assets/icons/service-checklists-icons/measureIcon.svg";
import photographIcon from "assets/icons/service-checklists-icons/photographIcon.svg";

import { RegisterProps } from "dtos/ServiceChecklistDTO";

import * as S from "./styles";

type CardChecklistDetailProps = {
  nameCheckList?: string;
  checklistRegisters?: RegisterProps[];
  handleGoBack: () => void;
};

type Option = {
  [key: string]: string;
};

export function CardChecklistDetail({
  nameCheckList,
  checklistRegisters,
  handleGoBack,
}: CardChecklistDetailProps) {
  function typeIcon(typeI: string) {
    const opt: Option = {
      fotografar: photographIcon,
      medir: measureIcon,
      verificar: checkIcon,
      executar: runIcon,
    };
    return opt[typeI];
  }

  return (
    <>
      <S.Top>
        <S.ButtonClose onClick={() => handleGoBack()}>
          <img src={arrowLeft} alt="flecha pra esquerda" />
        </S.ButtonClose>
      </S.Top>
      <S.Title>Nome do checklist*</S.Title>
      <S.Subtitle>{nameCheckList}</S.Subtitle>

      {checklistRegisters &&
        checklistRegisters.map((currentRegister, index) => {
          return (
            <S.CardContainer key={index}>
              <S.WrapperInfoCard>
                <S.ContainerImg>
                  <img src={typeIcon(currentRegister.type)} alt="imagem" />
                  <S.WrapperData>
                    <h6>{currentRegister.name}</h6>
                    <p>{currentRegister.description}</p>
                  </S.WrapperData>
                </S.ContainerImg>
              </S.WrapperInfoCard>
            </S.CardContainer>
          );
        })}
    </>
  );
}
