import { useCallback, useEffect, useState } from "react";

import { useToast } from "hooks/toast";
import addMaskMoney from "utils/addMaskMoney";

import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";
import { api } from "services/api";
import { ServiceOptionsInputProps } from "dtos/ServiceDTO";

import * as S from "./styles";
import { useServiceDropDown } from "hooks/servicesDropDown";
import { useSelectedServiceOrder } from "hooks/selectedServiceOrder";
import { CheckBoxProducts } from "components/CheckBoxProducts";
import {
  ProductPropsUsedInContext,
  useSelectedProducts,
} from "hooks/budget/selectedProducts";
import { ProductItemDTO } from "dtos/ProductItemDTO";
import axios from "axios";
import { searchDatalayer } from "utils/pushDataLayer";
import debounce from "lodash.debounce";

type ListProductsProps = {
  handleToggleOpen: () => void;
  handleCreateProduct: () => void;
  handleEditProduct: (id: number) => void;
};

interface ProductsResponse {
  products: ProductItemDTO[];
}

export function ListProducts({
  handleToggleOpen,
  handleCreateProduct,
  handleEditProduct,
}: ListProductsProps) {
  const { addToast } = useToast();
  const { selectedProductsInContext, handleSetSelectedProducts } =
    useSelectedProducts();

  const { reset } = useServiceDropDown();

  const { handleSetOptionsInputService } = useSelectedServiceOrder();

  const [searchInputValue, setSearchInputValue] = useState("");
  const [productsSelectedForBudget, setProductsSelectedForBudget] = useState<
    ProductPropsUsedInContext[]
  >(selectedProductsInContext);
  const [productsAvailableToSelect, setProductsAvailableToSelect] = useState<
    ProductPropsUsedInContext[]
  >([]);

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadProducts(search?: string) {
    try {
      const { data } = await api.get<ProductsResponse>("products", {
        params: {
          limit: 100,
          offset: 0,
          search,
        },
      });

      const productsMapped = data.products.map((product) => {
        return {
          ...product,
          quantity: 1,
          total: product.price,
          formattedTotal: addMaskMoney(product.price),
          isChecked: productsSelectedForBudget.find(
            (prodSelected) => prodSelected.id === product.id
          )
            ? true
            : false,
        };
      });

      const sortedProducts = sortProductsById(productsMapped);

      const filteredProducts = sortedProducts.filter((selectedProduct) => {
        const filterProduct = selectedProductsInContext?.find((product) => {
          return product.id === selectedProduct.id;
        });

        return !filterProduct ? selectedProduct : null;
      });

      setProductsAvailableToSelect(filteredProducts);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar os produtos.",
        type: "error",
      });
    }
  }

  function sortProductsById(products: ProductPropsUsedInContext[]) {
    return products.sort((a, b) => b.id - a.id);
  }

  function handleSaveProductsIntoBusinessProposals() {
    handleSetSelectedProducts(productsSelectedForBudget);
    handleToggleOpen();
  }

  function handleSearchProduct(text: string) {
    setIsSearching(true);

    setSearchInputValue(text);
    debounceFn(text);
    searchDatalayer({ search_term: text, success: true });
  }

  function handleSearchCancel() {
    setSearchInputValue("");
    loadProducts();
  }
  async function handlePressDeleteProduct(productId: number) {
    try {
      await api.delete(`/products/${productId}`);

      const newProductsSelectedForBudget = productsSelectedForBudget.filter(
        (productSelectedForBudget) => productSelectedForBudget.id !== productId
      );

      setProductsSelectedForBudget(newProductsSelectedForBudget);

      addToast({
        title: "Ok!!",
        description: "Produto excluído com sucesso.",
        type: "success",
      });

      loadProducts();
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível excluir o produto.",
        type: "error",
      });
    }
  }

  function resetDataNewService() {
    reset();
    handleSetOptionsInputService({} as ServiceOptionsInputProps);
  }

  function handleToggleSelectProduct(productId: number) {
    const newProductsAvailableToSelect = productsAvailableToSelect.map(
      (product) => {
        if (product.id === productId) {
          return {
            ...product,
            isChecked: !product.isChecked,
          };
        } else {
          return product;
        }
      }
    );

    const newProductsSelectedForBudget = newProductsAvailableToSelect.filter(
      (selectedProduct) => selectedProduct.isChecked
    );

    setProductsAvailableToSelect(newProductsAvailableToSelect);
    setProductsSelectedForBudget([
      ...selectedProductsInContext,
      ...newProductsSelectedForBudget,
    ]);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getProducts, 3000), []);

  function getProducts(search?: string) {
    if (!!search) {
      loadProducts(search);
      setIsSearching(false);

      return;
    }

    loadProducts();

    setIsSearching(false);
  }

  return (
    <>
      <S.Content>
        <S.WrapperSearch>
          <SearchInput
            placeholder="Procure por um produto."
            description="Selecione um ou mais produtos para compor o orçamento."
            searchValue={searchInputValue}
            onChange={(event) => handleSearchProduct(event.target.value)}
            handleCancel={handleSearchCancel}
            loadingInput={isSearching}
          />
        </S.WrapperSearch>

        <S.Wrapper>
          <Button
            typeButton="outline"
            onClick={() => {
              resetDataNewService();
              handleCreateProduct();
            }}
          >
            Criar novo produto
          </Button>
        </S.Wrapper>

        <S.ListCardsService>
          {productsAvailableToSelect.length < 1 ? (
            <S.MessageDiv>
              <p>
                Você não criou nenhum produto,
                <br></br>
                clique em "Criar novo produto" para começar.
              </p>
            </S.MessageDiv>
          ) : (
            productsAvailableToSelect?.map((product) => (
              <S.Wrapper key={product.id}>
                <CheckBoxProducts
                  data={product}
                  onSelect={() => handleToggleSelectProduct(product.id)}
                  // eslint-disable-next-line react/jsx-no-bind
                  handleChangeProduct={handleEditProduct}
                  // eslint-disable-next-line react/jsx-no-bind
                  handleDeleteProduct={handlePressDeleteProduct}
                />
              </S.Wrapper>
            ))
          )}
        </S.ListCardsService>

        <S.Footer>
          <Button onClick={handleSaveProductsIntoBusinessProposals}>
            {productsSelectedForBudget.length} produto(s) selecionado(s)
            <span style={{ margin: "0 30px" }}>|</span>
            Adicionar produto
          </Button>
        </S.Footer>
      </S.Content>
    </>
  );
}
