import React, { useState } from "react";

import ProductIcon from "assets/myProducts.svg";

import { EditButton } from "components/EditButton";
import { DeleteButton } from "components/DeleteButton";
import { AlertModal } from "components/AlertModal";

import addMaskMoney from "utils/addMaskMoney";

import * as S from "./styles";
import { ProductPropsUsedInContext } from "hooks/budget/selectedProducts";
import { useHistory } from "react-router";

type CheckBoxProductProps = {
  data: ProductPropsUsedInContext;
  onSelect: () => void;
  handleChangeProduct: (idProduct: number) => void;
  handleDeleteProduct: (idProduct: number) => void;
  spacingPrice?: boolean;
  dontUseCheckbox?: boolean;
};

export function CheckBoxProducts({
  data,
  onSelect,
  handleChangeProduct,
  handleDeleteProduct,
  spacingPrice,
  dontUseCheckbox,
}: CheckBoxProductProps) {
  const [isSelected, setIsSelected] = useState(data.isChecked || false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const history = useHistory();

  function handleSelectProduct() {
    setIsSelected((prev) => !prev);
    onSelect();
  }

  function handleToggleModal() {
    handleDeleteProduct(data.id);
    setIsVisibleModal(false);
  }

  return (
    <>
      <AlertModal
        isVisible={isVisibleModal}
        title="Deseja apagar?"
        description="Essa ação não poderá ser desfeita. Tem certeza que deseja apgar esse produto?"
        action="choose"
        handleConfirm={() => handleToggleModal()}
        onCloseModal={() => setIsVisibleModal(false)}
      />

      <S.Container isSelected={dontUseCheckbox ? false : isSelected}>
        <S.Button onClick={() => handleSelectProduct()}>
          {!dontUseCheckbox && (
            <S.CheckBox>
              <S.CheckedFilled isSelected={isSelected} />
            </S.CheckBox>
          )}

          <S.Content>
            <S.WrapperDetail>
              <img src={data.photos[0]?.link || ProductIcon} alt={data.name} />

              <S.WrapperInfo>
                <S.Title spacingPrice={spacingPrice}>
                  {!!data.name && data.name}{" "}
                  <div> {addMaskMoney(data.price)} </div>
                </S.Title>
                <S.Label>{data.brand && `Marca: ${data.brand} `}</S.Label>
                <S.DetailsContainer>
                  {!!data.warranty && (
                    <>
                      {Object.keys(data.warranty).length > 0 ? (
                        <S.DescriptionLabel>
                          Garantia: {data.warranty.time}{" "}
                          {data.warranty.extension}
                        </S.DescriptionLabel>
                      ) : (
                        <div />
                      )}
                    </>
                  )}
                  <S.Details
                    onClick={() => {
                      history.push(`/products/details-products/${data.id}`);
                    }}
                  >
                    Ver detalhes
                  </S.Details>
                </S.DetailsContainer>
              </S.WrapperInfo>
            </S.WrapperDetail>
          </S.Content>
        </S.Button>

        <S.WrapperActions>
          <EditButton onClick={() => handleChangeProduct(data.id)} />
          <DeleteButton onClick={() => setIsVisibleModal(true)} />
        </S.WrapperActions>
      </S.Container>
    </>
  );
}
