import React, { useState } from "react";

import { ListClients } from "./ListClients";
import { FormNewClient } from "./FormNewClient";

type ModalProps = {
  listclients: boolean;
  createClient: boolean;
};

type ModalName = "listclients" | "createClient";

type ModalClientProps = {
  handleToggleOpen: () => void;
  module?: "budget" | "serviceOrder" | "PMOC" | "client";
};

export function ModalClient({ handleToggleOpen, module }: ModalClientProps) {
  const [modalOpened, setModalOpened] = useState({
    listclients: true,
    createClient: false,
    addressClient: false,
  } as ModalProps);

  const { listclients, createClient } = modalOpened;

  function handleSwapModals(modal: ModalName) {
    const listModalOpened = Object.keys(modalOpened).map((key) => {
      if (key === modal) {
        return { [key]: true };
      }

      return { [key]: false };
    });

    setModalOpened(Object.assign({}, ...listModalOpened));
  }

  return (
    <>
      {listclients && (
        <ListClients
          module={module}
          handleToggleOpen={handleToggleOpen}
          handleSwapCreateClient={() => handleSwapModals("createClient")}
        />
      )}

      {createClient && (
        <FormNewClient
          module={module}
          handleToggleOpen={() => handleSwapModals("listclients")}
          handleToggleModal={handleToggleOpen}
        />
      )}
    </>
  );
}
