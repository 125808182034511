import styled, { css } from "styled-components";
import { shade } from "polished";

type MessageProps = {
  status: "em-analise" | "respondido" | "concluido";
};

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 auto;
    max-width: 1580px;
    height: 100%;
    padding: 12px 30px 0;
    margin-bottom: 10px;

    header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.title};
        font-weight: ${theme.font.bold};
        margin-left: 30px;
      }

      & > div {
        display: flex;
        align-items: center;
      }
      & > div:last-of-type {
        width: 34%;
        max-width: 440px;
      }
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    text-align: left;
    letter-spacing: 0.31px;
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
    opacity: 1;
    margin-top: 20px;
    width: 40%;
    text-align: center;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 94%;
    max-width: 1440px;
    margin-top: 20px;
  }
  `}
`;

export const Feedback = styled.div`
  ${({ theme }) => css`
    width: 470px;
    background-color: ${theme.colors.menu_detail_light};
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 25px;
  `}
`;

export const StatusWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: ${theme.font.light};
    font-size: 13px;
    letter-spacing: 0.26px;
    color: ${theme.colors.financial_title};
    opacity: 1;
  `}
`;

export const BadgeStatus = styled.div<MessageProps>`
  ${({ theme, status }) => css`
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
    background-color: ${status === "em-analise"
      ? theme.colors.model
      : status === "concluido"
      ? theme.colors.primary
      : theme.colors.schedule};
  `}
`;

export const FeedbackMessage = styled.div`
  ${({ theme }) => css`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    margin: 10px 0 15px 0;
    letter-spacing: 0.26px;
    font-size: 13px;
    color: ${theme.colors.financial_text};
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary};
    border-radius: 10px;
    top: 244px;
    left: 336px;
    width: 439px;
    height: 45px;
    opacity: 1;
  `}
`;
export const ButtonText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.financial_title};
    font-size: 12px;
    font-family: ${theme.font.light};
    letter-spacing: 0.26px;
    opacity: 1;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 470px;
  bottom: 20px;
  padding-bottom: 30px;
`;

export const ContainerOptionModal = styled.div`
  ${({ theme }) => css`
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
  `}
`;

export const CloseModal = styled.button`
  height: 100%;
  width: 100%;
`;

export const ContentOption = styled.div`
  ${({ theme }) => css`
    width: 500px;
    border-radius: 15px 15px 0px 0px;
    opacity: 1;
    padding: 20px 25px 40px;
    height: 30%;
    background: ${theme.colors.border};
  `}
`;

export const HeaderModal = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const CloseButton = styled.button`
  height: 25px;
  justify-content: center;
  align-items: center;
`;

export const TextCloseModal = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 12px;
    font-family: ${theme.font.normal};
  `}
`;

export const ModalTitle = styled.p`
  ${({ theme }) => css`
    text-align: center;
    letter-spacing: 0.31px;
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.title};
    font-weight: ${theme.font.bold};
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 15px;
  `}
`;

export const IconsView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const EmailSVG = styled.button`
  ${({ theme }) => css`
    border-radius: 10px;
    width: 95px;
    height: 95px;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border: 1px solid #b895ff;
  `}
`;

export const ChatSVG = styled.button`
  ${({ theme }) => css`
    border-radius: 10px;
    width: 95px;
    height: 95px;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border: 1px solid #d3ff9a;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-family: ${theme.font.normal};
    color: ${theme.colors.financial_text};
    letter-spacing: 0.26px;
    line-height: 16px;
    text-align: center;
    padding-top: 10px;
  `}
`;

export const Main = styled.div`
  width: 100%;
  min-height: 150px;
`;

export const WrapperDropdown = styled.div`
  margin-bottom: 18px;
  border: 1px solid #232129;
`;

export const Footer = styled.div`
  width: 100%;
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonConfirm = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 45px;
    color: ${theme.colors.background};
    font-size: ${theme.font.sizes.small};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.primary};
    border-radius: 8px;
    transition: background 0.2s;

    & > img {
      transform: scale(0.3);
    }

    &:hover {
      background: ${shade(0.2, theme.colors.primary)};
    }
  `}
`;

export const MessageSVG = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerEmptyPage = styled.div`
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
