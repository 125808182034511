import { useEffect, useState } from "react";
import { api } from "services/api";
import { useParams } from "react-router";
import { FormEditProduct } from "components/ModalProducts/FormEditProduct";
import * as S from "./styles";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ModalRight } from "components/ModalRight";
import { ProductPropsUsedInContext } from "hooks/budget/selectedProducts";
import { useToast } from "hooks/toast";
import axios from "axios";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { useHistory } from "react-router";
import addMaskMoney from "utils/addMaskMoney";

import ProductEmptyImage from "assets/product-empty-image.svg";

interface ProductRouter {
  productId: string;
}

const DetailsProducts = () => {
  const { productId } = useParams<ProductRouter>();
  const { addToast } = useToast();
  const history = useHistory();

  const [isOpenFormEditProducts, setIsOpenFormEditProducts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<ProductPropsUsedInContext>(
    {} as ProductPropsUsedInContext
  );

  const [photos, setPhotos] = useState(product?.photos);

  const fetchProduct = async () => {
    try {
      setIsLoading(true);

      const { data } = await api.get(`products/${productId}`);
      setPhotos(data.photos);

      setProduct(data);
      setIsLoading(false);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível carregar os produtos.",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectPhoto = (index: number) => {
    const photosCopy = [...photos];
    const selected = photosCopy.splice(index, 1);

    photosCopy.push(...selected);
    setPhotos(photosCopy);
  };
  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <>
          <S.Content>
            <S.Header>
              <ArrowButton handleFunction={() => history.goBack()} />
            </S.Header>
            <S.ContainerProduct>
              <S.Images>
                {!!photos?.length ? (
                  <>
                    <S.ContainerVeriticalPhotos>
                      {photos?.map((photo, index) => {
                        if (index < 3) {
                          return (
                            <>
                              <S.VerticalPhoto
                                onClick={() => selectPhoto(index)}
                                key={photo?.id}
                                src={photo.link}
                              />
                            </>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                      {Array.from({
                        length: Math.max(0, 3 - photos.length),
                      }).map(() => {
                        return <S.BlankPhoto src={ProductEmptyImage} />;
                      })}
                    </S.ContainerVeriticalPhotos>

                    <S.SelectedPhoto src={photos[photos.length - 1].link} />
                  </>
                ) : (
                  <S.SelectedPhoto src={ProductEmptyImage} />
                )}
              </S.Images>
              <S.Informations>
                <div>
                  <S.Title>{product.name}</S.Title>
                  <S.Label>Valor: {addMaskMoney(product?.price)}</S.Label>
                  <S.Label>
                    {product.brand && `Marca: ${product.brand}`}
                  </S.Label>

                  <S.Label>
                    {product?.warranty?.time &&
                      `Garantia: ${product.warranty.time} ${product.warranty.extension}`}
                  </S.Label>

                  {product?.observations && (
                    <S.Label style={{ marginTop: "15px" }}>
                      Observações: {product.observations}
                    </S.Label>
                  )}
                </div>
                <Button
                  onClick={() => {
                    setIsOpenFormEditProducts(true);
                  }}
                >
                  Editar produto
                </Button>
              </S.Informations>
            </S.ContainerProduct>

            <ModalRight
              isOpen={isOpenFormEditProducts}
              handleToggleOpen={() => {
                setIsOpenFormEditProducts(!isOpenFormEditProducts);
              }}
            >
              <FormEditProduct
                refetchListProducts={fetchProduct}
                idProduct={Number(productId)}
                handleGoBack={() => {
                  setIsOpenFormEditProducts(false);
                }}
              />
            </ModalRight>
          </S.Content>
        </>
      )}
    </>
  );
};
export { DetailsProducts };
