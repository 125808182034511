import { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { ClientArea } from "components/ClientArea";
import { ArrowButton } from "components/ArrowButton";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Dropdown } from "components/DropDown";
import { CardResponsible } from "./CardResponsible";
import { UnityArea } from "./UnityArea";

import LinearEditSvg from "assets/icons/linear-edit.svg";

import { useClient } from "hooks/budget/client";
import { useToast } from "hooks/toast";
import { usePmoc } from "hooks/usePmoc";
import { useHistory } from "react-router";

import { api } from "services/api";
import getValidationErrors from "utils/getValidationErrors";
import { generateRandomString } from "utils/generateRandomString";
import { hasLettersAndNumbers } from "utils/verifyHasLettersAndNumbers";
import { hasLowercaseLetters } from "utils/hasLowercaseLetters";

import * as S from "./styles";

import { AlertModal } from "components/AlertModal";

type Errors = {
  [key: string]: string;
};

const DROPDOWN_OPTIONS = [
  {
    id: 1,
    value: "meses",
    name: "Mês(es)",
  },
  {
    id: 2,
    value: "anos",
    name: "Ano(s)",
  },
];

export function InitialPmocForm() {
  const history = useHistory();
  const { addToast } = useToast();
  const { handleSetInitialPmocProps, unity, hasUnity, handleSetPeriodProps } =
    usePmoc();

  const [idPmoc, setIdPmoc] = useState("");

  const { client, hasClient } = useClient();

  const [isLoading, setIsLoading] = useState(false);

  const [contractPeriod, setContractPeriod] = useState("");
  const [contractPeriodType, setContractPeriodType] = useState("");

  const [hasError, setHasError] = useState<Errors>({} as Errors);

  const [modalErrorMessage, setModalErrorMessage] = useState(false);

  const [hasErrorOnHash, setHasErrorOnHash] = useState<string[]>([]);
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [visibleButtonSaveHash, setVisibleButtonSaveHash] = useState(false);
  const [randomHash, setRandomHash] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const randomHash = generateRandomString(6);
    setRandomHash(randomHash);
  }, []);

  async function verifyErrors() {
    let hasValidationErrors = false;

    if (!hasClient) {
      setHasError((errors) => ({
        ...errors,
        client: "Cliente é obrigatório.",
      }));
      hasValidationErrors = true;
    }

    if (!hasUnity) {
      setHasError((errors) => ({ ...errors, unity: "Unidade é obrigatório." }));
      hasValidationErrors = true;
    }

    if (contractPeriod.length === 0) {
      setHasError((errors) => ({
        ...errors,
        contractPeriod: "Período é obrigatório.",
      }));
      hasValidationErrors = true;
    }

    if (contractPeriodType.length === 0) {
      setHasError((errors) => ({
        ...errors,
        contractPeriodType: "Período é obrigatório.",
      }));
      hasValidationErrors = true;
    }

    if (idPmoc.trim().length < 1) {
      setHasError((errors) => ({
        ...errors,
        idPmoc: "ID do PMOC é obrigatório.",
      }));
      hasValidationErrors = true;
    }

    if (!randomHash) {
      setHasError((errors) => ({
        ...errors,
        randomHash: "Código de acesso é obrigatório.",
      }));
      hasValidationErrors = true;
    }

    if (hasValidationErrors) {
      return true;
    }

    return false;
  }

  async function handleUpdatePmoc() {
    try {
      setIsLoading(true);

      if (await verifyErrors()) {
        addToast({
          type: "error",
          title: "Erro",
          description: "Preencha todos os campos obrigatórios.",
        });

        return;
      }

      setHasError({});

      const pmocData = {
        name: idPmoc,
        clientId: parseInt(client.id.toString()),
        unityId: parseInt(unity.id.toString()),
        period: parseInt(contractPeriod),
        frequency: contractPeriodType,
        pmocUserClientPass: randomHash,
      };

      const response = await api.post(`/pmoc`, pmocData);

      handleSetInitialPmocProps({
        id: response.data.id,
        name: idPmoc,
      });

      handleSetPeriodProps({
        period: pmocData.period,
        frequency: pmocData.frequency,
      });

      addToast({
        type: "success",
        title: "PMOC atualizado com sucesso.",
      });

      history.replace({
        pathname: `/pmoc/finish-register/${response.data.id}`,
        state: {
          disabledDelete: true,
          statusPmoc: "pending",
        },
      });
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setHasError(errors);
        addToast({
          title: "Ops...",
          description: "Preencha todos os campos obrigatórios",
          type: "error",
        });
        return;
      }
      addToast({
        title: "Erro",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response?.data.error
            : "Não foi possível finalizar o cadastro do PMOC",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleChangeInput(text: string) {
    setRandomHash(text.trim().toUpperCase());

    if (inputRef.current?.value.trim()) setVisibleButtonSaveHash(true);
    else setVisibleButtonSaveHash(false);
  }

  function handleToggleEnableInput() {
    setIsInputDisabled(false);
    inputRef.current?.focus();
  }

  function handleSaveRandomHash() {
    let hashErrors: string[] = [];
    if (!randomHash) {
      hashErrors = [...hashErrors, "Campo obrigatório"];
    }

    if (randomHash.length !== 6) {
      hashErrors = [...hashErrors, "O campo deve conter 6 caracteres."];
    }

    if (!hasLettersAndNumbers(randomHash)) {
      hashErrors = [...hashErrors, "O campo deve conter letras e números."];
    }

    if (hasLowercaseLetters(randomHash)) {
      hashErrors = [...hashErrors, "Todas as letras tem que ser maiúsculas."];
    }

    if (hashErrors.length > 0) {
      setHasErrorOnHash(hashErrors);
      return;
    }

    setHasErrorOnHash([]);
    setIsInputDisabled(true);
    setVisibleButtonSaveHash(false);
  }

  return (
    <S.Container>
      <AlertModal
        isVisible={modalErrorMessage}
        title="Selecione um ambiente e complete os dados dos equipamentos"
        description={`Não existem ambientes selecionados para se vincular ao PMOC.\n Clique em "Ambientes e equipamentos" para selecionar e vincular um ambiente.`}
        action="confirm"
        handleConfirm={() => setModalErrorMessage(false)}
        onCloseModal={() => setModalErrorMessage(false)}
      />

      <header>
        <div>
          <ArrowButton />
        </div>

        <div>
          <S.Title>Novo PMOC</S.Title>
        </div>
      </header>

      <div className="content">
        <S.Wrapper>
          <Input
            name="ID do PMOC*"
            placeholder="Ex.: PMOC do João"
            onChange={(e) => setIdPmoc(e.target.value)}
            value={idPmoc}
            hasError={hasError.idPmoc}
            onFocusClearError={() => setHasError({ ...hasError, idPmoc: "" })}
          />
        </S.Wrapper>

        <S.Wrapper>
          <CardResponsible />
        </S.Wrapper>

        <S.Wrapper>
          <ClientArea hasError={hasError.client} module="PMOC" />
        </S.Wrapper>

        <S.Wrapper>
          <UnityArea hasError={hasError.unity} />
        </S.Wrapper>

        <S.Wrapper>
          <S.InlineContent>
            <div
              className="input"
              style={{
                marginBottom:
                  !!hasError.contractPeriodType || !!hasError.contractPeriod
                    ? 18
                    : 0,
              }}
            >
              <Input
                name="Período do contrato*"
                placeholder="Ex.: 1"
                type={"number"}
                onChange={(e) => setContractPeriod(e.target.value)}
                hasError={hasError.contractPeriod}
                onFocusClearError={() =>
                  setHasError((errors) => ({
                    ...errors,
                    contractPeriod: "",
                  }))
                }
              />
            </div>

            <div
              className="dropdown"
              style={{
                marginBottom:
                  hasError.contractPeriod && !hasError.contractPeriodType
                    ? 18
                    : 0,
              }}
            >
              <Dropdown
                options={DROPDOWN_OPTIONS}
                placeholder="Selecione"
                onClickedValue={(element) => {
                  setHasError((errors) => ({
                    ...errors,
                    contractPeriodType: "",
                  }));
                  if (element.value) {
                    setContractPeriodType(element.value);
                  }
                }}
                hasError={hasError.contractPeriodType}
              />
            </div>
          </S.InlineContent>
        </S.Wrapper>

        <S.WrapperInput>
          <div>
            <Input
              name="Código de acesso da PMOC*"
              placeholder="Ex: A1B2C3"
              value={randomHash}
              onChange={(e) => handleChangeInput(e.target.value)}
              customRef={inputRef}
              readOnly={isInputDisabled}
              labelIcon="Ativar campo de código"
              icon={visibleButtonSaveHash ? "" : LinearEditSvg}
              onClickIcon={handleToggleEnableInput}
              hasError={hasError.randomHash || hasErrorOnHash[0]}
              onFocusClearError={() =>
                setHasError({ ...hasError, randomHash: "" })
              }
              maxLength={6}
            />
          </div>

          {visibleButtonSaveHash && (
            <S.ButtonSaveHash onClick={handleSaveRandomHash}>
              Salvar
            </S.ButtonSaveHash>
          )}
        </S.WrapperInput>

        {hasErrorOnHash.length > 0 &&
          hasErrorOnHash.map((error) => (
            <S.WrapperHashError>
              <span>*{error}</span>
            </S.WrapperHashError>
          ))}

        <S.WrapperInfo>
          <span>
            O código de acessso serve para liberar os dados da PMOC no painel do
            cliente.
          </span>
        </S.WrapperInfo>

        <Button
          onClick={handleUpdatePmoc}
          loading={isLoading}
          disabled={isLoading || visibleButtonSaveHash}
        >
          Avançar
        </Button>
      </div>
    </S.Container>
  );
}
