import React, { useEffect, useState } from "react";

import axios from "axios";

import { Button } from "components/Button";
import { LoadingProfiz } from "components/LoadingProfiz";

import { ServiceOportunityProps } from "dtos/ServiceOportunityDTO";

import { useHistory } from "react-router";

import { useToast } from "hooks/toast";
import { useClient } from "hooks/budget/client";
import { useServiceOpportunityTabIndex } from "hooks/opportunityServiceTabIndex";

import apiv2 from "services/apiv2";
import { api } from "services/api";

import { ArrowButton } from "components/ArrowButton";

import { SendMessageModal } from "components/SendMessageModal";
import { useSelectedService } from "hooks/budget/selectedServices";

import addMaskMoney from "utils/addMaskMoney";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";

import { ServiceChecklistDTO } from "dtos/ServiceChecklistDTO";
import { ClientProps, UnityDTO } from "dtos/ClientDTO";
import { ServiceDTO } from "dtos/ServiceDTO";
import { ServiceAPIProps } from "dtos/PmocEnvironmentDTO";

import * as S from "./styles";

type ServiceProps = {
  id: number;
  idBudgetService: number;
  status: "pending" | "scheduled" | "concluded" | "canceled";
  scheduledDate: string;
  quantity: number;
  total?: number;
  service: {
    categoryId: number;

    id: number;
    name: string;
    price: number;

    description?: string;
    brand?: string;
    service?: {
      id: number;
      name: string;
    };
    equipment?: {
      id: number;
      name: string;
    };
    equipmentType?: {
      id: number;
      name: string;
    };
    capacity?: {
      id: number;
      name: string;
    };
    runtime?: {
      time?: number;
      extension?: string;
    };
    warranty?: {
      time?: string;
      extension?: string;
    };
    serviceChecklist?: ServiceChecklistDTO[];
  };
};

export function ServiceOrderOpportunityDetail() {
  const { addToast } = useToast();
  const history = useHistory();

  const { handleSetSelectedServices, handleSetCheckedServices } =
    useSelectedService();

  const {
    handleSetClient,
    handleSetComingFromOSOpportunity,
    handleSetOpportunityId,
    handleSetUnityClient,
  } = useClient();
  const { handleSetSelectedIndex } = useServiceOpportunityTabIndex();

  const { handleSetSelectedPromptPayment } = useSelectedPaymentConditions();

  const opportunityId = history.location.pathname.split("/")[2];

  const [isLoading, setIsLoading] = useState(true);
  const [opportunity, setOpportunity] = useState({} as ServiceOportunityProps);
  const [serviceOrderId, setServiceOrderId] = useState("");
  const [serviceSequencialNumber, setServiceSequencialNumber] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/opportunities/${opportunityId}`);
        setServiceOrderId(response.data.serviceOrderId);
        setServiceSequencialNumber(response.data.sequentialServiceOrderId);
        setOpportunity(response.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [opportunityId]);

  async function handleUnarchiveOpportunity() {
    try {
      setIsLoading(true);

      await api.delete(`/opportunities/${opportunityId}/unarchive`);
      addToast({
        title: "Oportunidade desarquivada com sucesso.",
        description: "",
        type: "success",
      });

      handleSetSelectedIndex(0);
      history.push("/service-opportunity");
    } catch (error) {
      addToast({
        type: "error",
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível arquivar a oportunidade.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleGenerateBudget() {
    try {
      setIsLoading(true);

      const { data } = await apiv2.get(
        `budgets/service-order/${serviceOrderId}`
      );

      const mappedServices = data.services.map((service: ServiceProps) => {
        return {
          ...service.service,
          idBudgetService: service.idBudgetService,
          quantity: service.quantity,
          total: service.service.price,
          formattedTotal: addMaskMoney(service.service.price),
          checked: true,
        };
      });

      const services = await validateIfServiceExists(mappedServices);

      const priceArray = mappedServices.map((service: ServiceProps) => {
        return Number(service.total);
      });

      const orderServiceTotalPrice = priceArray.reduce(
        (prev: number, curr: number) => prev + curr,
        0
      );

      handleSetSelectedPromptPayment([
        {
          description: "À Vista",
          index: "promptPayment",
          discount: null,
          formattedTotal: addMaskMoney(orderServiceTotalPrice),
          total: orderServiceTotalPrice,
        },
      ]);

      await validateIfClientExists(data.client);

      handleSetComingFromOSOpportunity(true);

      handleSetSelectedServices(services!);
      handleSetCheckedServices(services!);

      handleSetOpportunityId(Number(opportunityId));

      history.push("/budgets/create/services");
    } catch (error) {
      addToast({
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível aprovar a oportunidade no momento.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function validateIfClientExists(clientFromOpportunity: ClientProps) {
    try {
      const clientsResponse = await api.get("clients");

      const clientsIds = clientsResponse.data.clients.map(
        (client: ClientProps) => {
          return client.id;
        }
      );

      if (!clientsIds.includes(clientFromOpportunity.id)) {
        const response = await api.post("clients", clientFromOpportunity);
        await setDefaultUnity(response.data.id);
        handleSetClient(response.data);
      } else {
        handleSetUnityClient({
          ...clientFromOpportunity.address,
          clientId: clientFromOpportunity.id,
        } as UnityDTO);
        handleSetClient(clientFromOpportunity);
      }
    } catch (error) {
      addToast({
        title: "Ops.",
        description: "Não foi possível gerar o cliente",
        type: "error",
      });
    }
  }

  async function setDefaultUnity(clientId: number) {
    try {
      const response = await api.get(`/clients/${clientId}/unities`, {
        params: {
          limit: 50,
          offset: 0,
          client_id: clientId,
        },
      });

      const defaultUnity = response.data.unities.find(
        (unity: UnityDTO) => unity.default === true
      );

      handleSetUnityClient(defaultUnity);
    } catch (error) {
      addToast({
        title: "Ops.",
        description: "Não foi possivel encontrar o endereço do cliente!",
        type: "error",
      });
    }
  }

  async function validateIfServiceExists(
    servicesFromOpportunity: ServiceDTO[]
  ) {
    try {
      const servicesResponse = await api.get("services");

      const servicesIds = servicesResponse.data.services.map(
        (service: ServiceAPIProps) => {
          return service.id;
        }
      );

      const selectedServices: ServiceDTO[] = [];

      servicesFromOpportunity.forEach(async (service: ServiceDTO) => {
        if (!servicesIds.includes(service.id)) {
          const serviceFormatted = formatService(service);

          await api.post("/service", serviceFormatted);

          const servicesResponse = await api.get("services");

          const lastIndex = servicesResponse.data.services.length - 1;

          const latestService = servicesResponse.data.services[lastIndex];

          selectedServices.push({
            ...service,
            id: latestService.id,
          });
        } else {
          selectedServices.push(service);
        }
      });

      return selectedServices;
    } catch (error) {
      addToast({
        title: "Ops.",
        description: "Não foi possível gerar o serviço",
        type: "error",
      });
    }
  }

  function formatService(service: ServiceDTO) {
    const formattedService = {
      categoryID: service.categoryId,
      serviceTypeID: service.service.id,
      equipmentID: service.equipment.id,

      ...(service.equipmentType!.id
        ? { equipmentTypeID: service.equipmentType!.id }
        : {}),

      ...(service.capacity!.id ? { capacityID: service.capacity!.id } : {}),

      price: service.total,
      runtime: {
        ...(service.runtime!.time && service.runtime!.time !== 0
          ? { time: service.runtime!.time }
          : {}),
        ...(service.runtime!.extension
          ? { extension: service.runtime!.extension }
          : {}),
      },
      warranty: {
        ...(service.warranty!.time && Number(service.warranty!.time) !== 0
          ? { time: Number(service.warranty!.time) }
          : {}),
        ...(service.warranty!.extension
          ? { extension: service.warranty!.extension }
          : {}),
      },

      description: service.description,
      ...(service.brand ? { brand: service.brand } : {}),
      ...(service.serviceChecklist!.length > 0
        ? { serviceChecklist: service.serviceChecklist![0].id }
        : {}),
    };

    return formattedService;
  }

  async function handleArchiveOpportunity() {
    setIsLoading(true);
    try {
      await api.put(`/opportunities/${opportunityId}/archive`);

      addToast({
        title: "Oportunidade arquivada com sucesso.",
        description: "",
        type: "success",
      });

      handleSetSelectedIndex(2);
      history.push("/service-opportunity");
    } catch (error) {
      addToast({
        title: "Oops!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível arquivar a oportunidade.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleShowOrderDetail() {
    handleSetOpportunityId(Number(opportunityId));
    history.push({
      pathname: `/service-order/concluded/${serviceOrderId}`,
      state: { before: "ServiceOrderOpportunityDetail" },
    });
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Container>
          <ArrowButton />

          <S.Title>Oportunidade de serviço</S.Title>
          <S.Subtitle>
            A OS {serviceSequencialNumber} completou 12 meses, contate o cliente
            para oferecer um novo serviço
          </S.Subtitle>

          {!!opportunity.archived ? (
            <>
              <S.ContainerButtons>
                <Button
                  typeButton={"disabled"}
                  disabled={true}
                  style={{ marginBottom: "15px" }}
                  onClick={() => handleShowOrderDetail()}
                >
                  Visualizar Ordem de serviço
                </Button>

                <Button
                  typeButton={"disabled"}
                  disabled={true}
                  style={{ marginBottom: "15px" }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Enviar mensagem ao cliente
                </Button>

                <Button
                  typeButton={"disabled"}
                  disabled={true}
                  style={{ marginBottom: "15px" }}
                  onClick={handleGenerateBudget}
                >
                  Aprovar e gerar orçamento
                </Button>
              </S.ContainerButtons>
            </>
          ) : opportunity.status === "approved" ? (
            <>
              <S.ContainerButtons>
                <Button
                  typeButton={"outline"}
                  style={{ marginBottom: "15px" }}
                  onClick={() => handleShowOrderDetail()}
                >
                  Visualizar Ordem de serviço
                </Button>

                <Button
                  typeButton={"disabled"}
                  disabled={true}
                  style={{ marginBottom: "15px" }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Enviar mensagem ao cliente
                </Button>

                <Button
                  typeButton={"disabled"}
                  disabled={true}
                  onClick={handleGenerateBudget}
                >
                  Aprovar e gerar orçamento
                </Button>
              </S.ContainerButtons>
            </>
          ) : (
            <>
              <S.ContainerButtons>
                <Button
                  typeButton={"outline"}
                  style={{ marginBottom: "15px" }}
                  onClick={() => handleShowOrderDetail()}
                >
                  Visualizar Ordem de serviço
                </Button>

                <Button
                  typeButton={"outline"}
                  style={{ marginBottom: "15px" }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Enviar mensagem ao cliente
                </Button>

                <Button onClick={handleGenerateBudget}>
                  Aprovar e gerar orçamento
                </Button>
              </S.ContainerButtons>
            </>
          )}

          <S.WrapperArchiveButton>
            {opportunity.status !== "approved" &&
              (opportunity.archived ? (
                <S.ArchiveButton onClick={handleUnarchiveOpportunity}>
                  <S.ArchiveButtonText>
                    Desarquivar oportunidade
                  </S.ArchiveButtonText>
                </S.ArchiveButton>
              ) : (
                <>
                  <S.ArchiveButton onClick={handleArchiveOpportunity}>
                    <S.ArchiveButtonText>
                      Arquivar oportunidade
                    </S.ArchiveButtonText>
                  </S.ArchiveButton>
                </>
              ))}
          </S.WrapperArchiveButton>
        </S.Container>
      )}
      {isModalVisible && (
        <SendMessageModal
          opportunityId={opportunityId}
          isVisible={isModalVisible}
          onCloseModal={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
}
