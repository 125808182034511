/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";

// import OnboardingCard from "components/OnboardingCard";
import { LoadingProfiz } from "components/LoadingProfiz";
import ActuationAreaModal from "components/ActuationAreaModal";
import PopUpNewsModal from "components/PopUpNewsModal";
import { Notification } from "components/Notification";
import { PermissionNotification } from "components/PermissionNotification";
import { ModuleCard } from "components/ModuleCard";
import { HomeModuleCard } from "components/HomeModuleCard";
import { HasPermissionAlert } from "components/Permission";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import BudgetSvg from "assets/budget.svg";
import ScheduleSvg from "assets/schedule.svg";
import ServiceOrderSvg from "assets/service-order.svg";
import PmocSvg from "assets/pmoc.svg";
import BannerJpg from "./banner.jpg";
import HelpIconSvg from "assets/help-icon.svg";
import ServiceOpportunitySVG from "assets/service-opportunity.svg";
import CertificationsSVG from "assets/icons/header-menu/certifications-icon.svg";
import ChecklistSVG from "assets/icons/header-menu/checklist-icon.svg";
import ClientsSVG from "assets/icons/header-menu/clients-icon.svg";
import EventsCalendarSVG from "assets/icons/header-menu/events-calendar-icon.svg";
import ManualsSVG from "assets/icons/header-menu/manuals-icon.svg";
import ServicesSVG from "assets/icons/header-menu/services-icon.svg";
import TeamsSVG from "assets/icons/permission/teams-icon.svg";
import ReceiptSvg from "assets/icons/financial-icons/receipt.svg";

import { useToast } from "hooks/toast";
import { User, useAuth } from "hooks/auth";
import { useTopMenu } from "hooks/topMenu";
import { useAccount } from "hooks/permission/account";
import { useServiceChecklist } from "hooks/serviceChecklist";

import { api } from "services/api";

import ProductIcon from "assets/myProducts.svg";

import * as S from "./styles";

interface BannerType {
  img: string;
  imgWeb: string;
  action: {
    data: string;
    type: string;
    detailId?: string;
    detailStatus?: string;
  };
}

export function Home() {
  const { whoami } = useAccount();

  const showUserInfo = whoami?.isMe
    ? {
        name: whoami.name,
      }
    : {
        name: whoami?.companyName,
        role: whoami?.profile.name,
      };
  const [banners, setBanners] = useState<BannerType[]>([]);
  const [loading, setLoading] = useState(false);

  const [, setTourModalVisible] = useState(false);
  const [modalPopUpNewVisible, setModalPopUpNewVisible] = useState(false);
  // const [currentStep, setCurrentStep] = useState(1);
  const [actuationAreaModalVisible, setActuationAreaModalVisible] =
    useState(false);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  const history = useHistory();
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();

  const { handleSetMenu } = useTopMenu();

  const { handleSetIsNotMenusAccess } = useServiceChecklist();

  useEffect(() => {
    handleSetMenu(false);
  }, []);

  useEffect(() => {
    async function loadUser() {
      const { data } = await api.get<User>("/users/me");
      updateUser(data);

      const userSeenOnboarding = data.onboarding.homeOnboarding;

      if (!userSeenOnboarding && !user.profileApproved) {
        setTourModalVisible(true);
      }

      if (data.areasOfExpertise.length < 1 && data.onboarding.homeOnboarding) {
        toggleActuationModal(true);
      }
    }

    loadUser();
  }, []);

  useEffect(() => {
    if (user.profileApproved && !!user?.popupNews?.id) {
      setModalPopUpNewVisible(true);
    }

    if (
      !actuationAreaModalVisible &&
      !!user?.popupNews?.id &&
      user.areasOfExpertise.length >= 1
    ) {
      setModalPopUpNewVisible(true);
    }

    if (user.areasOfExpertise.length < 1) {
      setActuationAreaModalVisible(true);
    }
  }, [actuationAreaModalVisible, user?.popupNews?.id, user.areasOfExpertise]);

  // async function updateUserOnboardingHome() {
  //   try {
  //     const updateUserData = updateUserOnboarding(user, "homeOnboarding");

  //     await api.put("/users/me", updateUserData);
  //     updateUser(updateUserData);
  //   } catch (error) {
  //     addToast({
  //       title: "Erro",
  //       description: "Ocorreu um erro ao atualizar o usuário.",
  //       type: "error",
  //     });
  //   }
  // }

  async function loadBanners() {
    try {
      setLoading(true);
      const { data } = await api.get("/banners/home");
      setBanners(data.banners);
    } catch {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao carregar os banners.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleClickOnBanner(index: number) {
    const action = banners[index].action;

    if (action.type === "url") {
      if (action.data) {
        const url =
          action.data.search("https") >= 0 || action.data.search("http") >= 0
            ? action.data
            : `https://${action.data}`;

        if (url.includes("vendas.profiz.com.br")) {
          try {
            if (user.profileComplete && user.profileApproved) {
              const { data } = await api.post("/webview/auth");
              window.open(data.url, "_blank");
            } else {
              addToast({
                title: "Ops!!",
                description: "Não foi possível fazer login na loja",
                type: "error",
              });
            }
          } catch (error) {
            addToast({
              title: "Ops!!",
              description:
                axios.isAxiosError(error) && error.response?.data.error
                  ? error.response.data.error
                  : "Não foi possível fazer login na loja",
              type: "error",
            });
          }
        } else {
          window.open(url, "_blank");
        }
      }
    } else {
      if (action.data === "manuals") {
        action.data = "brands";
      }

      if (action.data === "events") {
        action.data = "events-calendar";
        if (action.detailId) {
          localStorage.setItem("@Profiz:EventId", action.detailId);
        }
        action.detailId = "";
      }

      if (action.data === "checklists") {
        action.data = "service-checklist";
      }

      if (action.data === "budget") {
        action.data = "budgets";
      }

      if (action.data === "receipt" && !action.detailId) {
        action.data = "financial/receipts";
      }

      if (action.data === "receipt" && action.detailId) {
        action.data = "financial/edit-receipt";
      }

      if (action.data === "home") {
        return;
      }

      history.push(
        `/${action.data}/${action.detailStatus && action.detailStatus + "/"}${
          action.detailId
        }`
      );
    }
  }

  const toggleActuationModal = (value: boolean) => {
    if (user.areasOfExpertise.length < 1) {
      setActuationAreaModalVisible(value);
    }
  };

  function handleNavigateToProfile() {
    history.push("/profile");
  }

  useEffect(() => {
    loadBanners();
  }, []);

  useEffect(() => {
    return history.listen(() => {
      handleSetMenu(true);
    });
  }, [history]);

  if (loading) {
    return <LoadingProfiz isVisible={loading} />;
  }

  return (
    <S.Container>
      {/* {!user.profileApproved && (
        <OnboardingCard
          type={"homeOnboarding"}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isTourModalVisible={tourModalVisible}
          setIsTourModalVisible={setTourModalVisible}
          onUpdateOnboarding={updateUserOnboardingHome}
          expertiseModalOpen={(value: boolean) => toggleActuationModal(value)}
        />
      )} */}
      <ActuationAreaModal
        isOpen={actuationAreaModalVisible}
        setIsOpen={(value: boolean) => {
          setActuationAreaModalVisible(value);
        }}
      />

      <PopUpNewsModal
        isOpen={modalPopUpNewVisible}
        setIsOpen={setModalPopUpNewVisible}
      />

      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />

      <S.Header>
        <S.HeaderContent>
          <S.ProfileInfo onClick={handleNavigateToProfile}>
            {user?.photo && (
              <S.UserPhoto>
                <img src={user.photo} alt="Foto de perfil do usuário" />
              </S.UserPhoto>
            )}

            <S.ProfileDetail>
              <S.Text>Navegando como</S.Text>
              <p>
                {showUserInfo?.role && (
                  <>
                    <S.HighlightText>{showUserInfo.role}</S.HighlightText>
                    <S.Text> em </S.Text>
                  </>
                )}

                <S.HighlightText>{showUserInfo.name}</S.HighlightText>
              </p>
            </S.ProfileDetail>
          </S.ProfileInfo>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <PermissionNotification />

            <Notification />
          </div>
        </S.HeaderContent>

        <S.HeaderMenu>
          <S.WrapperCard>
            <HasPermissionAlert
              moduleHash="client"
              actionHash="view"
              navigateTo="/clients"
            >
              <ModuleCard svg={ClientsSVG} title="Clientes" />
            </HasPermissionAlert>
          </S.WrapperCard>

          <S.WrapperCard>
            <HasPermissionAlert
              moduleHash="service"
              actionHash="view"
              navigateTo="/services"
            >
              <ModuleCard svg={ServicesSVG} title="Meus serviços" />
            </HasPermissionAlert>
          </S.WrapperCard>

          <S.WrapperCard>
            <ModuleCard
              svg={ProductIcon}
              title="Meus produtos"
              onClick={() => {
                if (user?.profileDisapproved) {
                  setActiveModalRejectedRegistration(true);
                } else {
                  history.push("/products");
                }
              }}
            />
          </S.WrapperCard>

          <S.WrapperCard>
            <HasPermissionAlert
              moduleHash="service-checklist"
              actionHash="view"
              navigateTo="/service-checklist"
            >
              <ModuleCard
                svg={ChecklistSVG}
                title="Checklists e registros"
                onClick={() => {
                  handleSetIsNotMenusAccess(false);
                }}
              />
            </HasPermissionAlert>
          </S.WrapperCard>

          <S.WrapperCard>
            <ModuleCard
              svg={EventsCalendarSVG}
              title="Calendário de eventos"
              onClick={() => history.push("/events-calendar")}
            />
          </S.WrapperCard>

          <S.WrapperCard>
            <HasPermissionAlert
              navigateTo="/teams"
              grantPermission={user.profileComplete && user.profileApproved}
            >
              <ModuleCard svg={TeamsSVG} title="Equipes" />
            </HasPermissionAlert>
          </S.WrapperCard>

          <S.WrapperCard>
            <ModuleCard
              svg={CertificationsSVG}
              title="Minhas certificações"
              onClick={() => {
                if (user?.profileDisapproved) {
                  setActiveModalRejectedRegistration(true);
                } else {
                  history.push("/certifications");
                }
              }}
            />
          </S.WrapperCard>

          <ModuleCard
            svg={ManualsSVG}
            title="Manuais"
            onClick={() => history.push("/brands")}
          />
        </S.HeaderMenu>
      </S.Header>

      <div style={{ paddingLeft: "25px", marginTop: "-18px" }}>
        <div style={{ width: "90%" }}>
          {banners.length > 1 ? (
            <Carousel
              onClickItem={(index) => handleClickOnBanner(index)}
              emulateTouch
              showThumbs={false}
              autoPlay
              showStatus={false}
              infiniteLoop
              dynamicHeight
              renderIndicator={(clickHandler, isSelected) => (
                <S.Dot onClick={clickHandler} dotIsSelected={isSelected} />
              )}
            >
              {banners.map((item, index) => {
                return (
                  <S.Banner key={index}>
                    <img
                      style={{ objectFit: "contain" }}
                      src={item.imgWeb || item.img}
                      alt=""
                    />
                  </S.Banner>
                );
              })}
            </Carousel>
          ) : (
            <S.Banner>
              <img
                src={banners.length > 0 ? banners[0].img : BannerJpg}
                alt=""
              />
            </S.Banner>
          )}
        </div>
        <S.ContentMenu>
          <HasPermissionAlert
            moduleHash="budgets"
            actionHash="view"
            navigateTo="/budgets"
          >
            <HomeModuleCard
              svg={BudgetSvg}
              title="Orçamentos"
              description="Seus orçamentos de produtos e serviços"
            />
          </HasPermissionAlert>

          <HasPermissionAlert
            moduleHash="budgets-service-order"
            actionHash="view"
            navigateTo="/service-order"
          >
            <HomeModuleCard
              svg={ServiceOrderSvg}
              title="Ordens de serviço"
              description="Entre para ver a listagem das suas ordens de serviço"
            />
          </HasPermissionAlert>

          <HasPermissionAlert
            actionHash="view"
            moduleHash="receipts"
            navigateTo="/financial/receipts"
          >
            <HomeModuleCard
              svg={ReceiptSvg}
              title="Recibos"
              description="Crie e controle os seus recibos"
            />
          </HasPermissionAlert>

          <HasPermissionAlert
            actionHash="view"
            moduleHash="pmoc"
            navigateTo="/pmoc"
          >
            <HomeModuleCard
              svg={PmocSvg}
              title="PMOC"
              description="Entre para ver a listagem dos seus PMOCs"
            />
          </HasPermissionAlert>

          <HomeModuleCard
            svg={ServiceOpportunitySVG}
            title="Oportunidades de serviço"
            description="Receba oportunidades e contate clientes"
            onClick={() => {
              if (user?.profileDisapproved) {
                setActiveModalRejectedRegistration(true);
              } else {
                history.push("/service-opportunity");
              }
            }}
          />

          <HasPermissionAlert
            moduleHash="schedule"
            actionHash="view-members-schedule"
            navigateTo="/schedule"
          >
            <HomeModuleCard
              svg={ScheduleSvg}
              title="Agenda"
              description="Acesse por aqui todos os seus serviços agendados"
            />
          </HasPermissionAlert>

          <HomeModuleCard
            svg={HelpIconSvg}
            title="Ajuda"
            description="Confira as dúvidas frequentes ou contate o suporte"
            onClick={() => history.push("/help")}
          />
        </S.ContentMenu>
      </div>
    </S.Container>
  );
}
