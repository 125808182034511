import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Button } from "components/Button";
import { ArrowButton } from "components/ArrowButton";
import { ContentBox } from "components/ContentBox";
import { ShowWhenHavePermission } from "components/Permission";
import { RenderLocationAddress } from "components/RedirectLocationAddress";
import { ModalRight } from "components/ModalRight";
import { ModalObservationDetail } from "components/ModalObservationDetail";
import { CardChecklistDetail } from "components/CardChecklistDetail";
import { ContentLeft } from "templates/ContentLeft";

import { ClientProps, UnityDTO } from "../../../../dtos/ClientDTO";
import { ServiceDTO } from "../../../../dtos/ServiceDTO";
import { RegisterProps } from "dtos/ServiceChecklistDTO";
import { LocalChecklistFromApiDTO } from "dtos/LocalChecklistFromApiDTO";

import { api } from "services/api";
import apiv2 from "services/apiv2";

import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import * as S from "./styles";

type RouteParams = {
  id: string;
};

type MaterialProps = {
  material: {
    id: number;
    item: {
      name: string;
    };
  };
  quantity: number;
};

type ServiceOrderResponse = {
  sequenceNumber: number;
  client: ClientProps;
  localChecklists: LocalChecklistFromApiDTO[];
  materials: MaterialProps[];
  services: ServiceDTO[];
  createdAt: string;
  observations: string;
};

type UnitiesResponse = {
  unities: UnityDTO[];
};

type CardChecklistDetailProps = {
  nameCheckList: string;
  checklistRegisters: RegisterProps[];
};

export function Pending() {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const [serviceOrder, setServiceOrder] = useState<ServiceOrderResponse>(
    {} as ServiceOrderResponse
  );

  const [unityClient, setUnityClient] = useState({} as UnityDTO);

  const [isLoading, setIsLoading] = useState(true);

  const [addressNotExists, setAddressNotExists] = useState(false);

  const [isOpenObservationDetail, setIsOpenObservationDetail] = useState("");
  const [isOpenCheckListDetail, setIsOpenCheckListDetail] =
    useState<CardChecklistDetailProps>({} as CardChecklistDetailProps);

  async function loadUnityData(clientResponse: ClientProps) {
    try {
      if (
        (clientResponse.address?.id &&
          clientResponse.address?.id?.toString() === "0") ||
        !clientResponse.address?.postalCode
      ) {
        setAddressNotExists(true);
        return;
      }

      if (clientResponse?.address && clientResponse?.address?.id) {
        clientResponse.address &&
          setUnityClient(clientResponse?.address as UnityDTO);
        return;
      }

      const response = await api.get<UnitiesResponse>(
        `/clients/${clientResponse.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: clientResponse.id,
          },
        }
      );

      const defaultUnity = response.data.unities.find((unity) => unity.default);

      if (response.data.unities.length < 1 || !defaultUnity) {
        setAddressNotExists(true);
        return;
      }

      defaultUnity && setUnityClient(defaultUnity);
    } catch {
      addToast({
        title: "Opss",
        description:
          "Ocorreu um erro ao carregar o endereço do cliente, tente novamente.",
        type: "error",
      });
    }
  }

  async function getServiceOrderDetail() {
    try {
      const response = await apiv2.get(`budgets/service-order/${id}`, {
        params: {
          accountId: whoami?.id,
        },
      });

      const formatedResponse = {
        ...response.data,
        services: response.data.services.map((currentService: ServiceDTO) => {
          return currentService.service;
        }),
      };

      setServiceOrder(formatedResponse);

      await loadUnityData(response.data.client);
    } catch (err) {
      addToast({
        title: "Ops...",
        description: "Erro ao buscar detalhes da ordem de serviço",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleToggleModalObservationDetail(observationDetail: string) {
    setIsOpenObservationDetail(observationDetail);
  }

  function handleToggleModalCheckListDetail({
    nameCheckList,
    checklistRegisters,
  }: CardChecklistDetailProps) {
    setIsOpenCheckListDetail({ nameCheckList, checklistRegisters });
  }

  useEffect(() => {
    getServiceOrderDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleDearchiveServiceOrder() {
    try {
      setIsLoading(true);

      await apiv2.put(`/budgets/service-order/${id}/dearchive`, {
        originUpdate: "web",
      });

      addToast({
        type: "success",
        title: "Ordem de serviço desarquivada",
        description: "",
      });

      history.push(`/service-order/pending/${id}`);
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Erro ao desarquivar ordem de serviço",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={!!isOpenObservationDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => handleToggleModalObservationDetail("")}
      >
        <ModalObservationDetail
          observation={isOpenObservationDetail}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() => handleToggleModalObservationDetail("")}
        />
      </ModalRight>
      <ModalRight
        isOpen={!!isOpenCheckListDetail?.nameCheckList}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() =>
          handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
        }
      >
        <CardChecklistDetail
          nameCheckList={
            isOpenCheckListDetail?.nameCheckList
              ? isOpenCheckListDetail?.nameCheckList
              : ""
          }
          checklistRegisters={
            isOpenCheckListDetail?.checklistRegisters
              ? isOpenCheckListDetail?.checklistRegisters
              : []
          }
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() =>
            handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
          }
        />
      </ModalRight>

      {!isLoading && (
        <ContentLeft>
          <header>
            <div>
              <ArrowButton
                handleFunction={() => {
                  history.push("/service-order", {
                    before: "ServiceOrderPendingDetail",
                  });
                }}
              />
            </div>

            <h1>Ordem de serviço {serviceOrder.sequenceNumber} </h1>
          </header>

          <S.WrapperArchivedText>
            <p>Ordem de serviço arquivada</p>
          </S.WrapperArchivedText>

          <S.Content>
            <S.Wrapper>
              <ContentBox title="Cliente">
                <S.ContentBoxTitle>
                  {serviceOrder?.client?.name}
                </S.ContentBoxTitle>
                <S.ContentBoxText>
                  {serviceOrder.client.cpf_cnpj}
                </S.ContentBoxText>
                <S.ContentBoxText>{serviceOrder.client.phone}</S.ContentBoxText>
                <S.ContentBoxText>{serviceOrder.client.email}</S.ContentBoxText>
              </ContentBox>
            </S.Wrapper>

            <S.Wrapper>
              <ContentBox title="Endereço">
                {addressNotExists ? (
                  <S.ContentBoxText>Sem endereço cadastrado.</S.ContentBoxText>
                ) : (
                  <>
                    <S.ContentBoxTitle>
                      {unityClient.name}{" "}
                      {unityClient.default ? "(principal)" : "(adicional)"}
                    </S.ContentBoxTitle>

                    <RenderLocationAddress
                      city={unityClient?.city}
                      complement={unityClient?.complement}
                      district={unityClient?.district}
                      number={Number(unityClient?.number)}
                      postalCode={unityClient?.postalCode}
                      street={unityClient?.street}
                      uf={unityClient?.uf}
                    />
                  </>
                )}
              </ContentBox>
            </S.Wrapper>

            <S.Wrapper>
              <p>Serviço</p>

              {serviceOrder.services.map((service) => (
                <ContentBox key={service.id}>
                  <S.ContentBoxTitle>
                    {service?.service?.name}
                  </S.ContentBoxTitle>
                  <S.ServiceInfoBox>
                    {!!service?.equipment?.name ? (
                      <span> {service.equipment.name} </span>
                    ) : (
                      <span>Serviço sem equipamento</span>
                    )}

                    {!!service?.equipmentType?.name && (
                      <span> {service.equipmentType.name} </span>
                    )}

                    {!!service?.capacity?.name && (
                      <span> {service.capacity.name} </span>
                    )}

                    {!!service?.brand && (
                      <span> | Marca: {service.brand} </span>
                    )}
                    {!!service?.runtime?.time && (
                      <span> | Execução: {service?.runtime?.time} </span>
                    )}

                    {!!service?.runtime?.time &&
                      !!service?.runtime?.extension && (
                        <span>{service?.runtime?.extension}</span>
                      )}

                    {!!service?.warranty?.time && (
                      <span>
                        {" "}
                        Garantia: {service.warranty.time}{" "}
                        {service.warranty.extension}{" "}
                      </span>
                    )}

                    {service?.description && (
                      <>
                        <br />
                        <S.ObservationButton
                          type="button"
                          onClick={() =>
                            handleToggleModalObservationDetail(
                              service.description!
                            )
                          }
                        >
                          Detalhamento do Serviço
                        </S.ObservationButton>
                      </>
                    )}

                    <br />
                    {service?.serviceChecklist &&
                      service?.serviceChecklist.length > 0 && (
                        <S.ObservationButton
                          type="button"
                          onClick={() =>
                            handleToggleModalCheckListDetail({
                              nameCheckList:
                                service?.serviceChecklist![0]?.name,
                              checklistRegisters:
                                service?.serviceChecklist![0]?.registers,
                            })
                          }
                        >
                          Detalhamento dos Registros
                        </S.ObservationButton>
                      )}
                  </S.ServiceInfoBox>
                </ContentBox>
              ))}
            </S.Wrapper>

            {serviceOrder.localChecklists.length > 0 && (
              <S.Wrapper>
                <p>Itens adicionais</p>

                <ContentBox>
                  {serviceOrder.localChecklists.map((currentChecklist) => {
                    const { checklist, type } = currentChecklist;

                    const status = checklist?.status.find(
                      (sts) => sts.type === type
                    );

                    return (
                      <S.ContentBoxText key={currentChecklist.checklist.id}>
                        <span>
                          {currentChecklist.checklist.name}: {status?.label}
                        </span>
                      </S.ContentBoxText>
                    );
                  })}
                </ContentBox>
              </S.Wrapper>
            )}

            {serviceOrder.materials.length > 0 && (
              <S.Wrapper>
                <p>Materiais</p>

                <ContentBox>
                  {serviceOrder.materials.map((currentMaterial) => (
                    <S.ContentBoxText key={currentMaterial.material.id}>
                      <span>
                        {currentMaterial.material.item.name} (
                        {currentMaterial.quantity})
                      </span>
                    </S.ContentBoxText>
                  ))}
                </ContentBox>
              </S.Wrapper>
            )}

            {!!serviceOrder.observations && (
              <S.Wrapper>
                <ContentBox title="Observação">
                  <S.ContentBoxObservationText>
                    {serviceOrder.observations}
                  </S.ContentBoxObservationText>
                </ContentBox>
              </S.Wrapper>
            )}

            <S.Wrapper>
              <Button disabled={true} typeButton="disabled">
                Agendar ordem de serviço
              </Button>
            </S.Wrapper>

            <S.WrapperArchiveButton>
              <ShowWhenHavePermission
                moduleHash="budgets-service-order"
                actionHash="edit-unarchive"
              >
                <Button
                  typeButton="textOnly"
                  onClick={handleDearchiveServiceOrder}
                >
                  Desarquivar ordem de serviço
                </Button>
              </ShowWhenHavePermission>
            </S.WrapperArchiveButton>
          </S.Content>
        </ContentLeft>
      )}
    </S.Container>
  );
}
