import { useEffect, useState } from "react";
import axios from "axios";

import { ArrowButton } from "components/ArrowButton";
import * as S from "./styles";
import apiv2 from "services/apiv2";
import { ServiceOrderDTO } from "dtos/ServiceOrderDTO2";
import { useAccount } from "hooks/permission/account";
import { useToast } from "hooks/toast";
import { ServiceWithChecklistDTO } from "dtos/ServiceWithChecklistDTO";
import { ModalChecklist } from "components/ModalChecklist";
import { ModalRight } from "components/ModalRight";
import {
  ModalGalleryRegisterImages,
  StagePhotoData,
} from "components/ModalGalleryRegisterImages";
import { ModalDetailReport } from "./ModalDetailReport";
import { LoadingProfiz } from "components/LoadingProfiz";
import { useServiceOrder } from "hooks/serviceOrder";
import { ServiceReportProps } from "dtos/ServiceReport";

export function ServiceDetailInServiceOrder() {
  const { 3: serviceOrderIdFromUrl, 5: idBudgetService } =
    window.location.pathname.split("/");
  const { whoami } = useAccount();
  const { addToast } = useToast();
  const {
    selectedServiceOrder,
    handleSetSelectedServiceOrder,
    handleSetCurrentService,
  } = useServiceOrder();

  const [loadingServiceDetail, setLoadingServiceDetail] = useState(true);

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const [openModalSignatures, setOpenModalSignatures] = useState(false);
  const [openModalReport, setOpenModalReport] = useState(false);

  const [serviceReport, setServiceReport] = useState({} as ServiceReportProps);
  const [serviceSignatures, setServiceSignatures] = useState(
    {} as StagePhotoData
  );

  const [currentServiceState, setCurrentServiceState] =
    useState<ServiceWithChecklistDTO>();

  async function getReportFromAPI() {
    try {
      const response = await apiv2.get<ServiceReportProps>(
        `budgets/order-service/${serviceOrderIdFromUrl}/budget-service/${idBudgetService}/report`
      );

      setServiceReport(response.data);
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Não foi possível buscar o relatório!",
      });
    }
  }

  useEffect(() => {
    if (selectedServiceOrder.isNewSignVersion) {
      getReportFromAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceOrder.isNewSignVersion]);

  useEffect(() => {
    getServiceOrderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getServiceOrderDetail() {
    try {
      const { data: serviceOrderDetail } = await apiv2.get<ServiceOrderDTO>(
        `/budgets/service-order/${serviceOrderIdFromUrl}`,
        {
          params: {
            accountId: whoami?.id,
          },
        }
      );

      const selectedCurrentService = serviceOrderDetail.services.find(
        (currentService) =>
          currentService.idBudgetService.toString() === idBudgetService
      );

      setCurrentServiceState(selectedCurrentService);
      handleSetCurrentService(
        selectedCurrentService as ServiceWithChecklistDTO
      );
      handleSetSelectedServiceOrder(serviceOrderDetail);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "erro ao carregar os dados do serviço",
        type: "error",
      });
    } finally {
      setLoadingServiceDetail(false);
    }
  }

  function handleToggleModalChecklist() {
    setOpenModalChecklist((oldState) => !oldState);
  }

  function handleToggleModalSignatures() {
    setOpenModalSignatures((oldState) => !oldState);
  }

  function handleToggleModalReport() {
    setOpenModalReport((oldState) => !oldState);
  }

  function handleOpenGallerySignaturesIntoService() {
    const mappedSignatures = currentServiceState?.service.serviceSigns?.map(
      (signature) =>
        ({
          id: signature.id,
          type:
            signature.signType === "provider-sign"
              ? "profile-sign"
              : "client-sign",
          link: signature.url,
        } as const)
    );

    setServiceSignatures({
      key: "",
      signatures: mappedSignatures,
    });

    handleToggleModalSignatures();
  }

  if (loadingServiceDetail) {
    return <LoadingProfiz isVisible={loadingServiceDetail} />;
  }

  return (
    <S.Container>
      <S.Header>
        <ArrowButton />
        <h4>Detalhes do serviço</h4>
      </S.Header>

      <S.CardContent>
        <S.Title style={{ marginBottom: 10 }}>
          {currentServiceState?.service.service.name}
        </S.Title>

        <S.WrapperInfo>
          <S.Text>
            {currentServiceState?.service.equipment.name ? (
              <>
                {currentServiceState?.service.equipment.name}
                {currentServiceState?.service.equipmentType?.name}
                {currentServiceState?.service.capacity?.name &&
                  ` | ${currentServiceState?.service.capacity.name}`}{" "}
                <br />
                {currentServiceState?.service.brand &&
                  `Marca: ${currentServiceState?.service.brand}`}
                <br />
                {currentServiceState?.service.runtime?.time &&
                  `Execução: ${currentServiceState?.service.runtime.time} ${currentServiceState?.service.runtime.extension}`}
                {currentServiceState?.service.warranty?.time &&
                  ` | Garantia: ${currentServiceState?.service.warranty.time} ${currentServiceState?.service.warranty.extension}`}
              </>
            ) : (
              <>Sem equipamento cadastrado</>
            )}
          </S.Text>

          {currentServiceState?.service.description && (
            <div style={{ marginTop: 15 }}>
              <S.Text>Detalhamento do serviço:</S.Text>
              <S.PreFormattedText>
                {currentServiceState?.service.description}
              </S.PreFormattedText>
            </div>
          )}
        </S.WrapperInfo>

        <S.WrapperInfo>
          {currentServiceState?.userExecution?.id && (
            <S.Text>
              Responsável pelo serviço:{" "}
              {currentServiceState?.userExecution?.name}
            </S.Text>
          )}

          {currentServiceState?.service?.serviceChecklist && (
            <S.ButtonText onClick={handleToggleModalChecklist}>
              Ver checklist -{" "}
              {currentServiceState?.service.serviceChecklist[0]?.name}
            </S.ButtonText>
          )}
        </S.WrapperInfo>

        {selectedServiceOrder.isNewSignVersion && serviceReport.id && (
          <S.WrapperInfo>
            <S.WrapperRow>
              <S.FooterTitle>Relatório final</S.FooterTitle>

              <S.ButtonTextBold onClick={handleToggleModalReport}>
                Ver relatório
              </S.ButtonTextBold>
            </S.WrapperRow>
          </S.WrapperInfo>
        )}

        {currentServiceState?.service.serviceSigns &&
          currentServiceState.service.serviceSigns?.length > 0 && (
            <S.WrapperRow>
              <div>
                <S.FooterTitle style={{ marginBottom: 6 }}>
                  Assinaturas
                </S.FooterTitle>
                <S.Text>
                  Quantidade: {currentServiceState?.service.serviceSigns.length}
                </S.Text>
              </div>

              <S.ButtonTextBold
                onClick={handleOpenGallerySignaturesIntoService}
              >
                Ver todas
              </S.ButtonTextBold>
            </S.WrapperRow>
          )}
      </S.CardContent>

      <ModalChecklist
        isOpen={openModalChecklist}
        setIsOpen={handleToggleModalChecklist}
        checklist={
          currentServiceState?.service?.serviceChecklist
            ? currentServiceState?.service.serviceChecklist[0]!
            : undefined
        }
      />

      <ModalRight
        isOpen={openModalSignatures}
        handleToggleOpen={handleToggleModalSignatures}
      >
        <ModalGalleryRegisterImages
          serviceName={currentServiceState?.service.service.name}
          stagePhotoData={serviceSignatures}
          handleToggleModal={handleToggleModalSignatures}
        />
      </ModalRight>

      <ModalRight
        isOpen={openModalReport}
        handleToggleOpen={handleToggleModalReport}
      >
        <ModalDetailReport
          report={serviceReport.report}
          handleToggleModal={handleToggleModalReport}
        />
      </ModalRight>
    </S.Container>
  );
}
